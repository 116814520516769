import { AddOrganization } from "./components/admin/organization/AddOrganization";
import { AddToS } from "./components/admin/tos/AddToS";
import { AddUser } from "./components/common/user/AddUser";
import AdminExecutionsList from "./components/admin/AdminExecutionsList";
import { ApproveToS } from "./components/organization/tos/ApproveToS";
import { CleanupConfiguration } from "./components/admin/CleanupConfiguration";
import { ERoutes } from "./types/enum/routes.enum";
import ExecutionsList from "./components/common/execution/ExecutionsList";
import Home from "./components/core/Home";
import OrganizationsList from "./components/admin/organization/OrganizationList";
import { ReadTos } from "./components/user/tos/ReadTos";
import { ResetPassword } from "./components/common/authentication/ResetPassword";
import SignIn from "./components/common/authentication/Signin";
import { UpdateOrganization } from "./components/admin/organization/UpdateOrganization";
import { UpdateToS } from "./components/admin/tos/UpdateToS";
import { UpdateUser } from "./components/common/user/UpdateUser";
import { UserLimits } from "./components/common/user/UserLimits";
import { UsersList } from "./components/common/user/UsersList";
import ToSList from "./components/common/tos/ToSList";
import {AgentsList} from './components/admin/agent/AgentsList';
import {UpdateAgent} from './components/admin/agent/UpdateAgent';
import {AddAgent} from "./components/admin/agent/AddAgent";

const publicRoutes = [{path: ERoutes.SIGN_IN, Component: SignIn}];

const privateAdminRoutes = [
    {path: ERoutes.ADMIN_EXECUTIONS, Component: AdminExecutionsList},

    {path: ERoutes.ADMIN_FILES_CONFIG, Component: CleanupConfiguration},
    
    {path: ERoutes.ADMIN_ORGANIZATIONS, Component: OrganizationsList},
    {path: ERoutes.ADMIN_ADD_ORGANIZATION, Component: AddOrganization},
    {path: ERoutes.ADMIN_EDIT_ORGANIZATION, Component: UpdateOrganization},

    {path: ERoutes.ADMIN_TOS, Component: ToSList},
    {path: ERoutes.ADMIN_ADD_TOS, Component: AddToS},
    {path: ERoutes.ADMIN_EDIT_TOS, Component: UpdateToS},
    
    {path: ERoutes.ADMIN_AGENTS, Component: AgentsList},
    {path: ERoutes.ADMIN_UPDATE_AGENT, Component: UpdateAgent},
    {path: ERoutes.ADMIN_ADD_AGENT, Component: AddAgent},
];

const privateManagerRoutes = [
    {path: ERoutes.TOS, Component: ToSList},
    {path: ERoutes.ORG_MANAGER_APPROVE_TOS, Component: ApproveToS},

    {path: ERoutes.USERS, Component: UsersList},
    {path: ERoutes.ADD_USER, Component: AddUser},
    {path: ERoutes.UPDATE_USER, Component: UpdateUser},
];

const privateUserRoutes = [
    {path: ERoutes.ROOT, Component: Home},
    {path: ERoutes.CHANGE_PASSWORD, Component: ResetPassword},
    {path: ERoutes.LIMITS, Component: UserLimits},

    {path: ERoutes.EXECUTIONS, Component: ExecutionsList},

    {path: ERoutes.USER_READ_TOS, Component: ReadTos},
];

export default {
    publicRoutes,
    privateAdminRoutes,
    privateManagerRoutes,
    privateUserRoutes
};
