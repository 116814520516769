import {Button, Paper, PasswordInput, Select, TextInput, Title} from '@mantine/core';
import {useFormik} from 'formik';
import useTranslation from 'src/i18next.config';
import {AddUserPayload, useUserService} from 'src/services/user';
import {useEffect, useState} from 'react';
import {ERoles} from 'src/types/enum/roles.enum';
import {showNotification} from '@mantine/notifications';
import {useOrganizationService} from 'src/services/organization';
import {Organization} from 'src/types/models/Organization';

import {userProfileActions} from 'src/store/reducers/user-reducer';
import {useNavigate} from 'react-router-dom';
import {ERoutes} from 'src/types/enum/routes.enum';

export function AddUser() {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const userService = useUserService();
    const userProfile = userProfileActions.useUserProfile();

    const organizationService = useOrganizationService();
    const [formCompleted, setFormCompleted] = useState(false);
    const [newUserRole, setNewUserRole] = useState<ERoles>(ERoles.USER);
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [eRoles, setEroles] = useState(Object.values(ERoles));

    const formik = useFormik<AddUserPayload>({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            organizationId: '',
            description: '',
            role: newUserRole,
            password: '',
        },
        onSubmit: addUserClicked,
    });

    useEffect(() => {
        // organization manager can't create an user with an admin role
        function filterRoles() {
            if (!userProfile.isAdmin) {
                const newERoles = eRoles.filter(role => role !== ERoles.ADMIN)
                setEroles(newERoles);
            }
        }
        filterRoles();
    }, [eRoles, userProfile]);

    useEffect(() => {
        if (!formCompleted) {
            const {firstName, lastName, email, role, password} = formik.values;
            if (firstName && lastName && email && role && password) {
                setFormCompleted(true);
            }
        }
    }, [formik]);

    useEffect(function () {
        if (organizations.length < 1) {
            if (userProfile.isAdmin) {
                organizationService.getOrganizations({
                    error: error => {
                        console.error(error);
                    },
                    success: res => {
                        setOrganizations(res);
                    },
                });
            } else {
                formik.setFieldValue('organizationId', userProfile.organizationId);
                formik.setFieldValue('role', ERoles.USER);
            }
        }
    }, []);

    async function addUserClicked(addUserPayload: AddUserPayload) {
        if (!formCompleted) {
            showNotification({
                title: t('formNotCompleted'),
                message: t('formNotCompletedMessage'),
                color: 'red',
            });
        } else {
            await userService.createUser(
                {
                    error: err => {
                        if (err.message) {
                            showNotification({
                                title: t('user.addNewUserNotification'),
                                message: err.message,
                                color: 'red',
                            });
                        }
                        else if (err.status == 403) {
                            showNotification({
                                message: t('user.maxUserNumberReached'),
                                color: 'red',
                            });
                        }
                        else {
                            showNotification({
                                title: t('user.addNewUserNotification'),
                                message: t('user.addNewUserErrorMessage'),
                                color: 'red',
                            });
                        }
                    },
                    success: () => {
                        showNotification({
                            title: t('user.addNewUserNotification'),
                            message: t('user.addNewUserSuccessMessage'),
                            color: 'green',
                        });
                        setFormCompleted(false);
                        formik.resetForm();

                        navigate(ERoutes.USERS);
                    },
                },
                addUserPayload
            );
        }
    }

    function displayOrganizationSelect() {
        if (organizations && userProfile.isAdmin)
            return (
                <div style={{marginTop: 10}}>
                    <Title order={6}>{t('organization.label')}</Title>
                    <Select
                        searchable
                        required={true}
                        placeholder={t('organization.label').toString()}
                        nothingFound={t('noOptions')}
                        data={organizations.map(organization => ({
                            value: organization._id,
                            label: organization.name,
                        }))}
                        id="organization"
                        onChange={value => {
                            formik.setFieldValue('organizationId', value);
                        }}
                        value={formik.values.organizationId}
                        error={
                            !!formik.touched.organizationId &&
                            !!formik.errors.organizationId &&
                            formik.errors.organizationId
                        }
                    />
                </div>
            );
    }

    function displayRoleInput() {
        return (
            <div style={{marginTop: 10}}>
                <Title order={6}>{t('role')}</Title>
                <Select
                    id="role"
                    required={true}
                    placeholder={t('role').toString()}
                    onChange={value => {
                        setNewUserRole(value as ERoles);
                        formik.setFieldValue('role', value);
                    }}
                    data={eRoles.map(role => {
                        return {
                            value: role,
                            label: t(`roles.${role}`).toString(),
                        };
                    })}
                    value={newUserRole}
                    error={!!formik.touched.role && !!formik.errors.role && formik.errors.role}
                />
            </div>
        );
    }

    return (
        <Paper
            p="md"
            shadow="md"
            component="form"
            onSubmit={formik.handleSubmit}
            noValidate
            style={{minWidth: '500px', width: '50%', margin: 'auto'}}
        >
            <Title order={2}>{t('user.addNewUser')}</Title>
            <div style={{marginTop: 20}}>
                <Title order={6}>{t('firstName')}</Title>
                <TextInput
                    id="firstName"
                    type="text"
                    required={true}
                    placeholder={t('firstName').toString()}
                    onChange={formik.handleChange}
                    value={formik.values.firstName}
                    error={!!formik.touched.firstName && !!formik.errors.firstName && formik.errors.firstName}
                />
            </div>
            <div style={{marginTop: 10}}>
                <Title order={6}>{t('lastName')}</Title>
                <TextInput
                    id="lastName"
                    type="text"
                    required={true}
                    placeholder={t('lastName').toString()}
                    onChange={formik.handleChange}
                    value={formik.values.lastName}
                    error={!!formik.touched.lastName && !!formik.errors.lastName && formik.errors.lastName}
                />
            </div>
            <div style={{marginTop: 10}}>
                <Title order={6}>{t('email')}</Title>
                <TextInput
                    id="email"
                    type="email"
                    required={true}
                    placeholder={t('emailPlaceholder').toString()}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    error={!!formik.touched.email && !!formik.errors.email && formik.errors.email}
                />
            </div>
            {displayOrganizationSelect()}
            <div style={{marginTop: 10}}>
                <Title order={6}>{t('description')}</Title>
                <TextInput
                    id="description"
                    type="text"
                    placeholder={t('description').toString()}
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    error={!!formik.touched.description && !!formik.errors.description && formik.errors.description}
                />
            </div>
            {displayRoleInput()}
            <div style={{marginTop: 10}}>
                <Title order={6}>{t('password')}</Title>
                <PasswordInput
                    id="password"
                    required={true}
                    placeholder={t('password').toString()}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    error={!!formik.touched.password && !!formik.errors.password && formik.errors.password}
                />
            </div>
            <Button color="cyan.8" type="submit" style={{marginTop: 20}}>
                {t('create')}
            </Button>
        </Paper>
    );
}
