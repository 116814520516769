import {combineReducers} from 'redux';

import {authReducer, AuthState} from './auth-reducer';
import {IUserProfile, userReducer} from './user-reducer';

export type RootState = {
    authReducer: AuthState;
    userReducer: IUserProfile;
};

export default combineReducers<RootState>({
    authReducer,
    userReducer,
});
