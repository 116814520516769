import {Button, Group, Paper, Stack, Text, Title} from '@mantine/core';
import { Calendar } from '@mantine/dates';
import { RichTextEditor } from '@mantine/rte';
import {showNotification} from '@mantine/notifications';
import {useEffect, useState} from 'react';

import { ToS } from 'src/types/models/ToS';
import useTranslation from 'src/i18next.config';

type ToSFormProps = {
    initialToS?: ToS;
    submitBtnLbl: string;
    title: string;
    onSave: (payload: ToS) => void;
};


export function ToSForm({initialToS, submitBtnLbl, title, onSave}: ToSFormProps) {
    const {t} = useTranslation();

    const [formCompleted, setFormCompleted] = useState(false);
    const [inputDate, setInputDate] = useState<Date>(new Date());
    const [inputToSContents, setInputToSContents] = useState<string>('');

    useEffect(() => {
        setInputToSContents(initialToS?.contents ?? '');
        if (initialToS) {
            setInputDate(new Date(initialToS.intoEffectAt));
        }
    }, []);

    useEffect(() => {
        if (inputToSContents.trim() !== '') {
            setFormCompleted(true);
        } else {
            setFormCompleted(false);
        }
    }, [inputDate, inputToSContents]);

    async function saveClicked(values: ToS) {
        if (!formCompleted) {
            if (inputToSContents.trim() == '') {
                alert("ToS contents cannot be empty");
            }
            showNotification({
                title: t('formNotCompleted'),
                message: t('formNotCompletedMessage'),
                color: 'red',
                top: 10,
                right: 10,
            });
        } else {
            onSave(values);
        }
    }

    return (
        <Paper
            p="md"
            shadow="md"
            component="form"
            noValidate
            style={{
                width: '80%', 
                margin: 'auto'
            }}
        >
            <Stack spacing="lg">
                <Title order={2}>{title}</Title>
                <Group
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Text 
                        size="lg"
                        weight={500}
                        underline={true}
                    >Terms of Service:</Text>
                    <RichTextEditor
                        id="contents"
                        onChange={(e) => setInputToSContents(e)}
                        placeholder="Type your Terms Of Service here..."
                        value={inputToSContents}
                        style={{ 
                            minHeight: `400px`,
                            width: `85%`,
                        }}
                    />
                </Group>
                <Group
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Text 
                        size="lg"
                        weight={500}
                        underline={true}
                    >Enters into effect starting:</Text>
                    <Calendar
                        id="intoEffectAt"
                        onChange={(e) => setInputDate(e ? e : new Date())}
                        value={inputDate} 
                    />
                </Group>
                <div style={{marginTop: 10, display: 'flex', columnGap: '1rem'}}>
                    <Button color="cyan.8" onClick={(e) => saveClicked({
                        approved: false,
                        _id: initialToS?._id || '',
                        contents: inputToSContents,
                        intoEffectAt: inputDate
                    })}>
                        {t(`${submitBtnLbl}`)}
                    </Button>
                </div>
            </Stack>
        </Paper>
    )
}
