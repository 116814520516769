import {Navigate} from 'react-router-dom';
import {userProfileActions} from 'src/store/reducers/user-reducer';
import {ERoutes} from 'src/types/enum/routes.enum';

function Home() {
    const userProfile = userProfileActions.useUserProfile();
    if (userProfile.isAdmin) {
        return <Navigate to={ERoutes.ADMIN_ORGANIZATIONS} replace />;
    } else {
        return <Navigate to={ERoutes.EXECUTIONS} replace />;
    }
}

export default Home;
