import {HttpMutators} from './http-mutators';
import {useHttp} from './http';
import {ERoles} from '../types/enum/roles.enum';

export type AddUserPayload = {
    firstName: string;
    lastName: string;
    email: string;
    organizationId: string;
    description: string;
    role: ERoles;
    password: string;
};
export type UpdateUserPayload = {
    firstName: string;
    lastName: string;
    description: string;
    role: ERoles;
};

export interface ILimitsResponse {
    enabledExecutionTime: boolean;
    maxExecutionTime: number;
    usedExecutionTime: number;

    enabledMaxExecutions: boolean;
    maxExecutions: number;
    usedExecutions: number;

    enabledMaxNumberOfUsers: boolean;
    maxNumberOfUser: number;
    numberOfUser: number;

    totalFilesSizeAllowedPerUser: number;
    enabledTotalFilesSizeAllowedPerUser: boolean;
    usedStorage: number;

    maxTimeout: number;
    enabledMaxTimeout: boolean;

    enabledMaxFilesSizePerExecution: boolean;
    maxFilesSizePerExecution: number;

    resetDay: number;
}

export type ResetUserPasswordPayload = {
    oldPassword: string;
    newPassword: string;
};

export const useUserService = () => {
    const {get, post, patch, del} = useHttp('/users');

    return {
        getUsers: (mutators: HttpMutators) => get(mutators),
        createUser: (mutators: HttpMutators, payload: any) => post(mutators, {payload}),
        getProfile: (mutators: HttpMutators) => get(mutators, {url: '/who-am-i'}),
        getCanDeleteUser: (mutators: HttpMutators, id: string) => get(mutators, {url: `/${id}/canDeleteUser`}),
        updateUser: (mutators: HttpMutators, id: string, payload: any) => patch(mutators, {url: `/${id}`, payload}),
        updateUserPassword: (mutators: HttpMutators, id: string, payload: any) =>
            patch(mutators, {url: `/${id}/update-password`, payload}),
        resetUserPassword: (mutators: HttpMutators, id: string, payload: ResetUserPasswordPayload) =>
            patch(mutators, {url: `/${id}/reset-password`, payload}),
        deleteUser: (mutators: HttpMutators, id: string) => del(mutators, {url: `/${id}`}),
        getLimits: (mutators: HttpMutators) => get(mutators, {url: `/limits`}),
    };
};
