import {Button, Paper, Select, TextInput, Title} from '@mantine/core';
import {useFormik} from 'formik';
import useTranslation from 'src/i18next.config';
import {UpdateAgentPayload} from 'src/services/agent';
import {useEffect, useState} from 'react';
import {showNotification} from '@mantine/notifications';
import {useNavigate} from 'react-router-dom';
import {useAgentService} from '../../../services/agent';
import {ERoutes} from '../../../types/enum/routes.enum';

export function AddAgent() {
    const {t} = useTranslation();
    const [formCompleted, setFormCompleted] = useState(false);
    const navigate = useNavigate();

    const agentService = useAgentService();

    const formik = useFormik<UpdateAgentPayload>({
        initialValues: {
            reference: "",
            url: "",
            type: "",
            active: true
        },
        onSubmit: addAgentClicked,
    });

    useEffect(() => {
        if (!formCompleted) {
            const {reference, url, type} = formik.values;
            setFormCompleted(reference != "" && url != "" && type != "");
        }
    }, [formik]);

    async function addAgentClicked(updateAgentPayload: UpdateAgentPayload) {
        if (!formCompleted) {
            showNotification({
                title: t('formNotCompleted'),
                message: t('formNotCompletedMessage'),
                color: 'red',
            });
        } else {
            await agentService.addAgent(
                {
                    error: err => {
                        if (err.message) {
                            showNotification({
                                message: err.message,
                                color: 'red',
                            });
                        } else {
                            showNotification({
                                title: t('agent.addAgentNotification'),
                                message: t('agent.addAgentErrorMessage'),
                                color: 'red',
                            });
                        }
                    },
                    success: () => {
                        showNotification({
                            title: t('agent.addAgentNotification'),
                            message: t('agent.addAgentSuccessMessage'),
                            color: 'green',
                        });
                        setFormCompleted(false);
                        navigate(ERoutes.ADMIN_AGENTS);
                    },
                },
                updateAgentPayload
            );
        }
    }

    return (
        <Paper
            p="md"
            shadow="md"
            component="form"
            onSubmit={formik.handleSubmit}
            noValidate
            style={{minWidth: '500px', width: '50%', margin: 'auto'}}
        >
            <Title order={2}>{t('agent.addAgentBtn')}</Title>
            <div style={{marginTop: 20}}>
                <Title order={6}>{t('agent.name')}</Title>
                <TextInput
                    id="reference"
                    type="reference"
                    required={true}
                    placeholder={t('agent.name').toString()}
                    onChange={formik.handleChange}
                    value={formik.values.reference}
                    error={!!formik.touched.reference && !!formik.errors.reference && formik.errors.reference}
                />
            </div>
            <div style={{marginTop: 20}}>
                <Title order={6}>{t('agent.type')}</Title>
                <TextInput
                    id="type"
                    type="type"
                    required={true}
                    placeholder={t('agent.type').toString()}
                    onChange={formik.handleChange}
                    value={formik.values.type}
                    error={!!formik.touched.type && !!formik.errors.type && formik.errors.type}
                />
            </div>
            <div style={{marginTop: 20}}>
                <Title order={6}>{t('agent.url')}</Title>
                <TextInput
                    id="url"
                    type="url"
                    required={true}
                    placeholder={t('agent.url').toString()}
                    onChange={formik.handleChange}
                    value={formik.values.url}
                    error={!!formik.touched.url && !!formik.errors.url && formik.errors.url}
                />
            </div>
            <div style={{marginTop: 20}}>
                <Title order={6}>{t('agent.active')}</Title>
                <Select
                    required={true}
                    type="active"
                    id="active"
                    value={formik.values.active ? t('agent.activated') :  t('agent.disabled')}
                    onChange={(event) => {
                        formik.handleChange
                        formik.setFieldValue('active', event == t('agent.activated'));
                    }}
                    data={[t('agent.activated') ?? '', t('agent.disabled') ?? '']}
                />
            </div>
            <Button color="cyan.8" type="submit" style={{marginTop: 20}}>
                {t('add')}
            </Button>
        </Paper>
    );
}
