import {createStyles, Button, Divider, Paper, Stack,Title} from '@mantine/core';
import {useEffect, useState} from 'react';

import { ToS } from 'src/types/models/ToS';
import useTranslation from 'src/i18next.config';
import useTosService from 'src/services/tos';

type ToSFormProps = {
    initialToS?: ToS;
    submitBtnLbl: string;
    title: string;
    onApprove: (payload: string) => void;
};

const useStyles = createStyles({
    tosContainer: {
        width: '50%',
        margin: 'auto'
    },
});

export function ToSFormApproval({initialToS, submitBtnLbl, title, onApprove}: ToSFormProps) {
    
    const {classes} = useStyles();
    const {getTosList} = useTosService();
    const {t} = useTranslation();

    const [showApproveToS, setShowApproveToS] = useState<boolean>(false);
    const [tosDate, setToSDate] = useState<Date>(initialToS?.intoEffectAt ?? new Date());
    const [tosContents, setToSContents] = useState<string>(initialToS?.contents ?? '');

    useEffect(() => {
        setToSContents(initialToS?.contents ?? '');
        // check if the ToS is already approved
        getTosList({
            error: error => {
                console.error(error);
            },
            success: res => {
                const approvedToS = res.find((t: any) => t._id == initialToS?._id);
                setShowApproveToS(approvedToS.approved == false);
            },
        });        
    }, []);

    async function saveClicked(tosId: string) {
        onApprove(tosId);
    }

    return (
        <Paper
            p="md"
            shadow="md"
            component="form"
            noValidate
            className={classes.tosContainer}
        >
            <Stack spacing="lg">
                <Title order={1}>{title}</Title>
                <Title order={2}>{`Into effect starting: ${new Date(tosDate).toLocaleDateString()}`}</Title>
                <Divider />
                <div style={{
                        overflowX: 'hidden',   
                        wordWrap: 'break-word',
                    }}
                    dangerouslySetInnerHTML={{
                    __html: tosContents
                }}></div>
                <Divider />
                {showApproveToS && <div style={{marginTop: 10, display: 'flex', columnGap: '1rem'}}>
                    <Button color="cyan.8" onClick={(e) => saveClicked(initialToS?._id || '')}>
                        {t(`${submitBtnLbl}`)}
                    </Button>
                </div>}
            </Stack>
        </Paper>
    )
}
