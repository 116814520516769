import {Text, Progress, Card} from '@mantine/core';

function ProgressCard(props: {
    displayName: string;
    max: number;
    current: number;
    displayAsPercentage: boolean;
    displayFunction?: (value: any) => string;
}) {
    props.displayAsPercentage ??= false;

    function getPercentage(current: number, max: number): number {
        return (current / max) * 100;
    }

    function displayValue() {
        if (props.displayAsPercentage) {
            return (
                <Text fz="lg" fw={500}>
                    {getPercentage(props.current, props.max)}% / 100%
                </Text>
            );
        } else if (props.displayFunction) {
            return (
                <Text fz="lg" fw={500}>
                    {props.displayFunction(props.current)} / {props.displayFunction(props.max)}
                </Text>
            );
        } else {
            return (
                <Text fz="lg" fw={500}>
                    {props.current} / {props.max}
                </Text>
            );
        }
    }

    return (
        <Card withBorder radius="md" p="lg">
            <Text fz="lg">{props.displayName}</Text>
            {displayValue()}
            <Progress value={getPercentage(props.current, props.max)} mt="md" size="lg" radius="xl" />
        </Card>
    );
}

export default ProgressCard;
