import {NavLink, ThemeIcon} from '@mantine/core';
import {useNavigate} from 'react-router-dom';
import {MainLinkProps} from 'src/types/interfaces/main-link';

function MainLink({icon, color, label, path}: MainLinkProps) {
    const navigate = useNavigate();
    const handleNavigation = (path: string) => navigate(path);

    return (
        <NavLink
            label={label}
            icon={
                <ThemeIcon color={color} variant="light">
                    {icon}
                </ThemeIcon>
            }
            onClick={() => handleNavigation(path)}
        />
    );
}

export function MainLinks(props: {links: MainLinkProps[]}) {
    return (
        <div>
            {props.links.map(link => (
                <MainLink {...link} key={link.label} />
            ))}
        </div>
    );
}
