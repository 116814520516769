import { Button, createStyles, NumberInput, Select, TextInput } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useState } from "react";

import { ERequestStatus } from "src/types/enum/request-status.enum";
import { ExecutionsSearchLimits, ExecutionsSearchPayload } from "src/types/interfaces/executions-search-payload";
import ExecutionsSearchQs from "src/types/interfaces/executions-search-sq";

interface ExecutionsSearchProps {
    onSearch: (payload: ExecutionsSearchQs) => void;
    orgRestriction?: boolean;
    userRestriction?: boolean;
}

const useStyles = createStyles({
    formContainer: {
        marginBottom: '1rem',
        paddingLeft: '1rem',
        paddingTop: '1rem',
    },
    formField: {
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: '1rem', 
        gap: '1rem',
    },
    submit: {
        marginTop: "20px"
    }
});

function ExecutionsSearch({ onSearch, orgRestriction, userRestriction }: ExecutionsSearchProps) {

    const [doneDate, setDoneDate] = useState<Date | null>(null);
    const [executionRequestId, setExecutionRequestId] = useState<string>('');
    const [limit, setLimit] = useState<ExecutionsSearchLimits>(50);
    const [orgName, setOrgName] = useState<string>('');
    const [runningDate, setRunningDate] = useState<Date | null>(null);
    const [status, setStatus] = useState<ERequestStatus>(ERequestStatus.ALL);
    const [userEmail, setUserEmail] = useState<string>('');

    const {classes} = useStyles();

    const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const payload: ExecutionsSearchQs = {
            limit
        };
        if (doneDate) {
            payload.doneDate = doneDate?.getTime();
        }
        if (executionRequestId.trim().length > 0) {
            payload.executionRequestId = executionRequestId;
        }
        if (orgName.trim().length > 0) {
            payload.orgName = orgName;
        }
        if (runningDate) {
            payload.runningDate = runningDate.getTime();
        }
        if (status != ERequestStatus.ALL) {
            payload.status = status;
        }
        if (userEmail.trim().length > 0) {
            payload.userEmail = userEmail;
        }
        onSearch(payload);
    };

    return <>
        <form onSubmit={handleSearch} className={classes.formContainer}>
            <div className={classes.formField}>
                <TextInput label="Execution id" value={executionRequestId} onChange={(event) => setExecutionRequestId(event.currentTarget.value)} />
                {!orgRestriction && <TextInput label="Organization name" value={orgName} onChange={(event) => setOrgName(event.currentTarget.value)} />}
                {!userRestriction && <TextInput label="User email" value={userEmail} onChange={(event) => setUserEmail(event.currentTarget.value)} />}
                <Select data={[ERequestStatus.ALL, ERequestStatus.NEW, ERequestStatus.RUNNING, ERequestStatus.DONE, ERequestStatus.ERROR, ERequestStatus.CANCELED]} label="Status" value={status} onChange={e => setStatus(e as ERequestStatus)} />
                <DatePicker label="Running date" value={runningDate} onChange={setRunningDate} />
                <DatePicker label="Done date" value={doneDate} onChange={setDoneDate} />
            </div>
            <div className={classes.formField}>
                <Select data={["10", "50", "100", "200"]} label="Number of results" value={limit.toString()} onChange={e => {
                    if (!e) e = "50";
                    const limit = parseInt(e) as ExecutionsSearchLimits;
                    setLimit(limit);
                }} />
                <Button type="submit" className={classes.submit}>Search</Button>
            </div>
        </form>
    </>

}

export default ExecutionsSearch;