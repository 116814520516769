import React from 'react';
export function Logo() {
    return (
        <div
            style={{
                paddingBottom: 6,
                height: 'fit-content',
                width: 'fit-content',
                marginTop: 30,
            }}
        >
            <img
                src="/images/logo.png"
                alt=""
                style={{
                    width: 50,
                    height: 50,
                }}
            />
        </div>
    );
}
