import {Card, Grid, Title, Text} from '@mantine/core';
import {useEffect, useState} from 'react';

import {humanFileSize} from 'src/helpers/file.helper';
import {ILimitsResponse} from 'src/services/user';
import ProgressCard from 'src/components/core/ProgressCard';
import useTranslation from 'src/i18next.config';
import {useUserService} from 'src/services';

export function UserLimits() {
    const {t} = useTranslation();
    const {getLimits} = useUserService();
    const [limits, setLimits] = useState({} as ILimitsResponse);

    useEffect(function () {
        getLimits({
            error: error => {
                console.error(error);
            },
            success: res => {
                setLimits(res);
            },
        });
    }, []);

    function cleanDisplayNumber(value: number): number {
        if (value % 1) {
            return parseFloat(value.toFixed(2));
        } else {
            return value;
        }
    }

    function displayUsedTime() {
        if (limits && limits.enabledExecutionTime) {
            return (
                <Grid.Col span={6}>
                    <ProgressCard
                        displayAsPercentage={false}
                        current={cleanDisplayNumber(limits.usedExecutionTime / 60)} // Convert to minutes & Fix float length
                        displayName={t('userLimits.usedExecutionTime')}
                        max={limits.maxExecutionTime/60}
                    ></ProgressCard>
                </Grid.Col>
            );
        }
    }

    function displayUsedExecutions() {
        if (limits && limits.enabledMaxExecutions) {
            return (
                <Grid.Col span={6}>
                    <ProgressCard
                        displayAsPercentage={false}
                        current={limits.usedExecutions}
                        displayName={t('userLimits.usedExecutions')}
                        max={limits.maxExecutions}
                    ></ProgressCard>
                </Grid.Col>
            );
        }
    }

    function displayTimeout() {
        if (limits && limits.enabledMaxTimeout) {
            return (
                <Grid.Col span={6}>
                    <Card withBorder radius="md" p="lg">
                        <Text fz="lg">{t('userLimits.maxTimeout')}</Text>
                        <Text fz="lg" fw={500}>
                            {limits.maxTimeout} {t('userLimits.minutes')}
                        </Text>
                    </Card>
                </Grid.Col>
            );
        }
    }

    function displayMaxFileSizePerExecution() {
        if (limits && limits.enabledMaxFilesSizePerExecution) {
            return (
                <Grid.Col span={6}>
                    <Card withBorder radius="md" p="lg">
                        <Text fz="lg">{t('userLimits.maxFilesSizePerExecution')}</Text>
                        <Text fz="lg" fw={500}>
                            {humanFileSize(limits.maxFilesSizePerExecution)}
                        </Text>
                    </Card>
                </Grid.Col>
            );
        }
    }

    function displayTotalFilesSizeAllowedPerUser() {
        if (limits && limits.enabledTotalFilesSizeAllowedPerUser) {
            return (
                <Grid.Col span={6}>
                    <ProgressCard
                        displayAsPercentage={false}
                        current={limits.usedStorage}
                        displayName={t('userLimits.totalFilesSize')}
                        displayFunction={humanFileSize}
                        max={limits.totalFilesSizeAllowedPerUser}
                    ></ProgressCard>
                </Grid.Col>
            );
        }
    }

    function displayMaxNumberOfUsers() {
        if (limits && limits.enabledMaxNumberOfUsers) {
            return (
                <Grid.Col span={6}>
                    <ProgressCard
                        displayAsPercentage={false}
                        current={limits.numberOfUser}
                        displayName={t('userLimits.userNumber')}
                        max={limits.maxNumberOfUser}
                    ></ProgressCard>
                </Grid.Col>
            );
        }
    }

    return (
        <>
            <Title order={2}>{t('limits')}</Title>

            <Grid style={{marginTop: 20}}>
                {displayUsedTime()}
                {displayUsedExecutions()}
                {displayTotalFilesSizeAllowedPerUser()}
                {displayMaxNumberOfUsers()}
                {displayMaxFileSizePerExecution()}
                {displayTimeout()}
            </Grid>
        </>
    );
}
