import {Button, NumberInput, Paper, Title} from '@mantine/core';
import {useFormik} from 'formik';
import useTranslation from 'src/i18next.config';
import {useEffect, useState} from 'react';
import {showNotification} from '@mantine/notifications';
import {EditConfigPayload, useConfigService} from 'src/services/config';

export function CleanupConfiguration() {
    const {t} = useTranslation();
    const configService = useConfigService();
    const [config, setConfig] = useState({} as EditConfigPayload);
    const [formCompleted, setFormCompleted] = useState(false);

    const formik = useFormik<EditConfigPayload>({
        initialValues: {
            daysBeforeDeletionForDone: 5,
            daysBeforeDeletionForDownloadUploadedFiles: 5,
            daysBeforeDeletionForDownloadResultedFiles: 5,
        },
        onSubmit: sendConfig,
    });

    useEffect(() => {
        if (!formCompleted) {
            const {
                daysBeforeDeletionForDone,
                daysBeforeDeletionForDownloadUploadedFiles,
                daysBeforeDeletionForDownloadResultedFiles,
            } = formik.values;
            if (
                daysBeforeDeletionForDone &&
                daysBeforeDeletionForDownloadUploadedFiles &&
                daysBeforeDeletionForDownloadResultedFiles
            ) {
                setFormCompleted(true);
            }
        }
    }, [formik]);

    useEffect(function () {
        configService.getConfig({
            error: error => {
                console.error(error);
            },
            success: res => {
                setConfig(res);
            },
        });
    }, []);

    useEffect(() => {
        if (
            config.daysBeforeDeletionForDone != null ||
            config.daysBeforeDeletionForDownloadUploadedFiles != null ||
            config.daysBeforeDeletionForDownloadResultedFiles != null
        ) {
            formik.setValues({
                daysBeforeDeletionForDone: config.daysBeforeDeletionForDone,
                daysBeforeDeletionForDownloadUploadedFiles: config.daysBeforeDeletionForDownloadUploadedFiles,
                daysBeforeDeletionForDownloadResultedFiles: config.daysBeforeDeletionForDownloadResultedFiles,
            });
        }
    }, [config]);

    async function sendConfig(editConfigPayload: EditConfigPayload) {
        if (!formCompleted) {
            showNotification({
                title: t('formNotCompleted'),
                message: t('formNotCompletedMessage'),
                color: 'red',
            });
        } else {
            await configService.updateConfig(
                {
                    error: err => {
                        if (err.message) {
                            showNotification({
                                message: err.message,
                                color: 'red',
                            });
                        } else {
                            showNotification({
                                title: t('cleanupConfiguration.editCleanupConfigNotification'),
                                message: t('cleanupConfiguration.editCleanupConfigErrorMessage'),
                                color: 'red',
                            });
                        }
                    },
                    success: () => {
                        showNotification({
                            title: t('cleanupConfiguration.editCleanupConfigNotification'),
                            message: t('cleanupConfiguration.editCleanupConfigSuccessMessage'),
                            color: 'green',
                        });
                        setFormCompleted(false);
                    },
                },
                editConfigPayload
            );
        }
    }

    return (
        <Paper
            p="md"
            shadow="md"
            component="form"
            onSubmit={formik.handleSubmit}
            noValidate
            style={{width: '50%', margin: 'auto'}}
        >
            <Title order={2}>{t('cleanupConfiguration.editCleanupConfig')}</Title>

            <NumberInput
                style={{flex: '1 0 auto'}}
                error={
                    !!formik.touched.daysBeforeDeletionForDone &&
                    !!formik.errors.daysBeforeDeletionForDone &&
                    formik.errors.daysBeforeDeletionForDone
                }
                id="daysBeforeDeletionForDone"
                label={t('cleanupConfiguration.daysBeforeDeletionForDone')}
                min={0}
                onChange={value => formik.setFieldValue('daysBeforeDeletionForDone', value)}
                value={formik.values.daysBeforeDeletionForDone}
                placeholder={t('cleanupConfiguration.daysBeforeDeletionForDone').toString()}
                required={true}
                type="number"
                withAsterisk
            />

            <NumberInput
                style={{flex: '1 0 auto', marginTop: 20}}
                error={
                    !!formik.touched.daysBeforeDeletionForDownloadUploadedFiles &&
                    !!formik.errors.daysBeforeDeletionForDownloadUploadedFiles &&
                    formik.errors.daysBeforeDeletionForDownloadUploadedFiles
                }
                id="daysBeforeDeletionForDownloadUploadedFiles"
                label={t('cleanupConfiguration.daysBeforeDeletionForDownloadUploadedFiles')}
                min={0}
                onChange={value => formik.setFieldValue('daysBeforeDeletionForDownloadUploadedFiles', value)}
                value={formik.values.daysBeforeDeletionForDownloadUploadedFiles}
                placeholder={t('cleanupConfiguration.daysBeforeDeletionForDownloadUploadedFiles').toString()}
                required={true}
                type="number"
                withAsterisk
            />

            <NumberInput
                style={{flex: '1 0 auto', marginTop: 20}}
                error={
                    !!formik.touched.daysBeforeDeletionForDownloadResultedFiles &&
                    !!formik.errors.daysBeforeDeletionForDownloadResultedFiles &&
                    formik.errors.daysBeforeDeletionForDownloadResultedFiles
                }
                id="daysBeforeDeletionForDownloadResultedFiles"
                label={t('cleanupConfiguration.daysBeforeDeletionForDownloadResultedFiles')}
                min={0}
                onChange={value => formik.setFieldValue('daysBeforeDeletionForDownloadResultedFiles', value)}
                value={formik.values.daysBeforeDeletionForDownloadResultedFiles}
                placeholder={t('cleanupConfiguration.daysBeforeDeletionForDownloadResultedFiles').toString()}
                required={true}
                type="number"
                withAsterisk
            />

            <Button color="cyan.8" type="submit" style={{marginTop: 20}}>
                {t('update')}
            </Button>
        </Paper>
    );
}
