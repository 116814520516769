import {showNotification} from '@mantine/notifications';
import {useNavigate} from 'react-router-dom';

import {ERoutes} from 'src/types/enum/routes.enum';
import { ToS } from 'src/types/models/ToS';
import useTosService from 'src/services/tos';
import useTranslation from 'src/i18next.config';
import { ToSForm } from './ToSForm';

export function AddToS() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {createTos: createToS} = useTosService();

    async function addToS(payload: ToS) {
        // resize img to fit container
        payload.contents = payload.contents.replace(/<img /g, '<img style="max-width:100%;object-fit:cover;height:auto;" ');
        await createToS(
            {
                error: () => {
                    showNotification({
                        title: t('tos.addNewToSNotification'),
                        message: t('tos.addNewToSErrorMessage'),
                        color: 'red',
                    });
                },
                success: () => {
                    showNotification({
                        title: t('tos.addNewToSNotification'),
                        message: t('tos.addNewToSuccessMessage'),
                        color: 'green',
                    });
                    navigate(ERoutes.ADMIN_TOS);
                },
            },
            payload
        );
    }

    return (
        <>
            <ToSForm
                onSave={addToS}
                title={t('tos.addNewToS')}
                submitBtnLbl="create"
            ></ToSForm>
        </>
    );
}
