import {Button, Paper, Table} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import { ERoutes } from 'src/types/enum/routes.enum';
import { ToS } from 'src/types/models/ToS';
import { userProfileActions } from 'src/store/reducers/user-reducer';
import useTosService from 'src/services/tos';
import useTranslation from 'src/i18next.config';

function ToSList() {

    // hooks
    const navigate = useNavigate();
    const tosService = useTosService();
    const {t} = useTranslation();
    const updateTosStatus = userProfileActions.useUpdateTosStatus();
    const userProfile = userProfileActions.useUserProfile();

    // state
    const [tosList, setTosList] = useState<ToS[]>([]);
    const tosArray = tosList ? tosList : [];

    const getSeeOrEditButton = (userProfile: any, tos: ToS) => {
        if (userProfile.isAdmin) {
            return (
                <Button
                    color="cyan.8"
                    variant="outline"
                    onClick={() => {
                        navigate(`/admin/tos/${tos._id}/edit`);
                    }}
                >
                    {t('edit')}
                </Button>
            );
        } else {
            return (
                <Button
                    color="cyan.8"
                    variant="outline"
                    onClick={() => {
                        navigate(`/org/tos/${tos._id}`);
                    }}
                >
                    {t('tos.seeToSBtn')}
                </Button>
            );
        }
    };

    useEffect(function () {
        if (tosList.length < 1) {
            tosService.getTosList({
                error: error => {
                    showNotification({
                        title: t('anErrorOccurredPleaseRetryLater'),
                        message: t('anErrorOccurredPleaseRetryLater'),
                        color: 'red',
                    });
                },
                success: (fetchedToSList: ToS[]) => {
                    setTosList(fetchedToSList);
                    if (!userProfile.isAdmin && fetchedToSList.length > 0) {
                        const latestTos = fetchedToSList.reduce((prev, current) => (prev.version! > current.version!) ? prev : current);
                        const orgApprovedLatestToS = latestTos.approved === true;
                        const tosMustBeApproved = latestTos.approved === false && new Date(latestTos.intoEffectAt).getTime() < Date.now();
                        updateTosStatus(orgApprovedLatestToS, tosMustBeApproved);
                    }
                },
            });
        }
    }, []);

    const rows = tosArray
        // sort by version DESC
        .sort((a, b) => {
            if (a.version && b.version) {
                return b.version - a.version;
            } else {
                return 0;
            }
        })
        .map((tos: ToS) => {
        const displayedPublishedDate = tos.createdAt ? new Date(tos.createdAt).toLocaleDateString() : '';
        const displayedIntoEffectAt = tos.intoEffectAt ? new Date(tos.intoEffectAt).toLocaleDateString() : '';
        return (
            <tr key={tos._id}>
                <td>{tos.version ?? 1}</td>
                <td>{displayedPublishedDate}</td>
                <td>{displayedIntoEffectAt}</td>
                {userProfile.isOrganizationManager && <td>{tos.approved ? '✅' : '❌'}</td>}
                <td>
                    {getSeeOrEditButton(userProfile, tos)}
                </td>
            </tr>
        );
    });

    return (
        <>
            {userProfile.isAdmin && <Button
                color="cyan.8"
                type="submit"
                style={{marginBottom: 20}}
                onClick={() => {
                    navigate(ERoutes.ADMIN_ADD_TOS);
                }}
            >
                {t('tos.addToSBtn')}
            </Button>}
            <Paper shadow="md" style={{width: 'fit-content', margin: 'auto'}}>
                <Table>
                    <thead className="table-head">
                        <tr>
                            <th style={{minWidth: '120px'}}>{t('tos.version')}</th>
                            <th style={{minWidth: '120px'}}>{t('tos.createdAt')}</th>
                            <th style={{minWidth: '120px'}}>{t('tos.intoEffectAt')}</th>
                            {userProfile.isOrganizationManager && <th style={{minWidth: '120px'}}>{t('tos.approved')}</th>}
                            <th style={{minWidth: '200px'}}>{t('actions')}</th>
                        </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </Table>
            </Paper>
        </>
    );
}

export default ToSList;
