import {createStore} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage
import {authActions} from './reducers/auth-reducer';
import rootReducer from './reducers/root-reducer';
import {userProfileActions} from './reducers/user-reducer';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['authReducer', 'userReducer'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer);
export const persistor = persistStore(store);

export function useClearStore() {
    const removeToken = authActions.useRemoveToken();
    const removeProfile = userProfileActions.useRemoveProfile();

    return async () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        await persistor.purge();
        await persistor.pause();
        removeToken();
        removeProfile();
    };
}
