import {Burger, Header, MediaQuery} from '@mantine/core';
import {Brand} from './_brand';
import {useState} from 'react';

function AppHeader() {
    const [opened, setOpened] = useState(false);

    return (
        <Header height={{base: 50, md: 70}} p="sm">
            <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                <MediaQuery largerThan="sm" styles={{display: 'none'}}>
                    <Burger opened={opened} onClick={() => setOpened(o => !o)} size="sm" mr="xl" />
                </MediaQuery>

                <Brand></Brand>
            </div>
        </Header>
    );
}

export default AppHeader;
