import {useState} from 'react';
import {useUserService} from 'src/services';
import {Button, Paper, PasswordInput} from '@mantine/core';
import {showNotification} from '@mantine/notifications';
import useTranslation from 'src/i18next.config';
import {authActions} from 'src/store/reducers/auth-reducer';
import {userProfileActions} from 'src/store/reducers/user-reducer';

export function ResetPassword() {
    const {t} = useTranslation();
    const userProfile = userProfileActions.useUserProfile();
    const userService = useUserService();
    const saveToken = authActions.useSaveToken();
    const saveProfile = userProfileActions.useSaveProfile();

    const [oldPasswordInput, setOldPasswordInput] = useState('');
    const [newPasswordInput, setNewPasswordInput] = useState('');
    const [confirmPasswordInput, setConfirmPasswordInput] = useState('');

    function resetPasswordHandler() {
        // checks if fields are not empty
        if (!oldPasswordInput || !newPasswordInput || !confirmPasswordInput) {
            showNotification({
                message: t('missingFields'),
                color: 'red',
            });
            return;
        }
        // checks if the new password and the confirmation are the same
        if (newPasswordInput !== confirmPasswordInput) {
            showNotification({
                message: t('passwordsDoNotMatch'),
                color: 'red',
            });
            return;
        }

        const resetUserPasswordPayload = {
            oldPassword: oldPasswordInput,
            newPassword: newPasswordInput,
        };
        userService.resetUserPassword(
            {
                error: () => {
                    showNotification({
                        message: t('anErrorOccurredPleaseRetryLater'),
                        color: 'red',
                    });
                },
                success: res => {
                    showNotification({
                        title: t('passwordUpdated'),
                        message: t('passwordUpdatedMessage'),
                        color: 'green',
                    });

                    // update tokens after password reset
                    const {token, refreshToken} = res;
                    saveToken({token, refreshToken});
                    saveProfile(token);
                },
            },
            userProfile.userId,
            resetUserPasswordPayload
        );
        setNewPasswordInput('');
        setOldPasswordInput('');
        setConfirmPasswordInput('');
    }

    return (
        <Paper p="md" shadow="md" style={{width: '600', margin: 'auto'}}>
            <PasswordInput
                label={t('enterYourOldPassword')}
                withAsterisk
                value={oldPasswordInput}
                onChange={event => setOldPasswordInput(event.target.value)}
            />
            <PasswordInput
                style={{marginTop: 10}}
                label={t('enterYourNewPassword')}
                withAsterisk
                value={newPasswordInput}
                onChange={event => setNewPasswordInput(event.target.value)}
            />
            <PasswordInput
                style={{marginTop: 10}}
                label={t('confirmYourNewPassword')}
                withAsterisk
                value={confirmPasswordInput}
                onChange={event => setConfirmPasswordInput(event.target.value)}
            />
            <Button
                color="cyan.8"
                onClick={() => {
                    resetPasswordHandler();
                }}
                style={{marginTop: 20}}
            >
                {t('changePassword')}
            </Button>
        </Paper>
    );
}
