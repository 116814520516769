import {HttpMutators} from './http-mutators';
import {useHttp} from './http';

export type UpdateAgentPayload = {
    reference?: string,
    url?: string,
    type?: string,
    active?: boolean
};

export const useAgentService = () => {
    const {get, patch, post} = useHttp('/agent');

    return {
        getAgents: (mutators: HttpMutators) => get(mutators),
        updateAgent: (mutators: HttpMutators, id: string, payload: any) => patch(mutators, {url: `/${id}`, payload}),
        addAgent: (mutators: HttpMutators, payload: any) => post(mutators, {url: ``, payload}),
    };
};
