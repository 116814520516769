export enum ERoutes {
    // base route
    ROOT = '/',

    ADMIN_FILES_CONFIG = '/admin/config',

    ADMIN_ORGANIZATIONS = '/admin/organizations',
    ADMIN_ADD_ORGANIZATION = '/admin/organizations/add',
    ADMIN_EDIT_ORGANIZATION = '/admin/organizations/:id/edit/',

    ADMIN_EXECUTIONS = '/admin/executions',

    ADMIN_TOS = '/admin/tos',
    ADMIN_ADD_TOS = '/admin/tos/add',
    ADMIN_EDIT_TOS = 'admin/tos/:id/edit/',

    ADMIN_AGENTS = "/admin/agents",
    ADMIN_UPDATE_AGENT = '/admin/agents/update',
    ADMIN_ADD_AGENT = '/admin/agents/add',

    CHANGE_PASSWORD = '/change-password',

    EXECUTIONS = '/executions',

    LIMITS = '/limits',

    ORG_MANAGER_APPROVE_TOS = '/org/tos/:id',

    REQUEST = '/request',

    SIGN_IN = '/sign-in',

    TOS = '/tos',
    USER_READ_TOS = '/tos/:id',

    USERS = '/users',
    ADD_USER = '/users/add',
    UPDATE_USER = '/users/update',
}
