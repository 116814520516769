import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {AgGridReact} from 'ag-grid-react';
import {IconSquareRoundedX} from '@tabler/icons-react';
import {showNotification} from '@mantine/notifications';
import {t} from 'i18next';
import {useEffect, useState} from 'react';
import {useMantineTheme} from '@mantine/core';

import { authActions } from 'src/store/reducers/auth-reducer';
import {ERequestActions} from 'src/types/enum/request-actions.enum';
import {ELocalesFormats} from 'src/types/enum/locales-formats.enum';
import {Execution} from 'src/types/models/Execution';
import {ExecutionActionMenu} from '../common/execution/ExecutionActionMenu';
import ExecutionsSearch from '../common/execution/ExecutionsSearch';
import ExecutionsSearchQs from 'src/types/interfaces/executions-search-sq';
import {formatDate} from 'src/helpers/format-date';
import {useExecutionService} from 'src/services';
import { ERequestStatus } from 'src/types/enum/request-status.enum';

function AdminExecutionsList() {

    const executionService = useExecutionService();
    const token = authActions.useToken();
    const theme = useMantineTheme();

    const [rows, setRows] = useState([]);

    const [executions, setExecutions] = useState<Execution[]>();

    function cancelExecutionClicked(executionId: string) {
        executionService.postStopExecution(
            {
                error: error => {
                    showNotification({
                        title: t('anErrorOccurredPleaseRetryLater'),
                        message: t('anErrorOccurredPleaseRetryLater'),
                        color: 'red',
                    });
                },
                success: res => {
                    showNotification({
                        title: t('stopExecutionRequestSent'),
                        message: t('stopExecutionRequestSent'),
                        color: 'green',
                    });
                    // update the status of the canceled execution
                    const updatedExecutions = executions?.map(exec => {
                        if (exec._id === executionId) {
                            exec.status = ERequestStatus.CANCELED;
                        }
                        return exec;
                    });
                    setExecutions(updatedExecutions);
                    displayData(updatedExecutions);
                },
            },
            {executionId}
        );
    }

    function displayData(res: any) {
        const executionsArray = res ? res : [];
        const rowData = executionsArray.map((exec: Execution) => {
            let creationDate: string | null = null;
            let runningDate: string | null = null;
            let doneDate: string | null = null;

            if (exec.creationDate) {
                creationDate = formatDate(new Date(exec.creationDate), ELocalesFormats.FR);
            }
            if (exec.runningDate) {
                runningDate = formatDate(new Date(exec.runningDate), ELocalesFormats.FR);
            }
            if (exec.doneDate) {
                doneDate = formatDate(new Date(exec.doneDate), ELocalesFormats.FR);
            }

            return {
                name: exec.name,
                label: exec.label,
                status: exec.status,
                _id: exec._id,
                creationDate: creationDate ?? 'None',
                runningDate: runningDate ?? 'None',
                doneDate: doneDate ?? 'None',
                organization: exec.organization?.name,
                userName: exec.user ? `${exec.user.firstName} ${exec.user.lastName}` : '',
                userEmail: exec.user ? exec.user.email : '',
                hasDeletedUploadFiles: exec.hasDeletedUploadFiles,
                hasDeletedResultFiles: exec.hasDeletedResultFiles,
                canCancelExecution: exec.canCancelExecution,
                canDeleteFiles: exec.canDeleteFiles,
                canDownloadResultFiles: exec.canDownloadResultFiles,
                canDownloadUploadFiles: exec.canDownloadUploadFiles,
            };
        });
        setRows(rowData);
    }

    async function searchExecutions(search: ExecutionsSearchQs) {
        try {
            const qs = new URLSearchParams(search as any).toString();
            const searchCall = await fetch(`${process.env.REACT_APP_API_URL}/request?${qs}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            const searchResult = await searchCall.json();
            setExecutions(searchResult)
            displayData(searchResult);
        } catch (error) {
            console.error(error);
            showNotification({
                title: t('errorFetchingData'),
                message: t('executions.errorFethingExecutions'),
                color: 'red',
            });
        }
    }

    const columnDefs = [
        {field: 'name', resizable: true, width: 150, filter: true, sortable: true},
        {field: 'label', resizable: true, width: 150, filter: true, sortable: true},
        {field: 'status', resizable: true, width: 150, filter: true, sortable: true},
        {field: '_id', resizable: true, width: 230, filter: true, sortable: true},
        {
            field: 'userName',
            resizable: true,
            width: 200,
            filter: true,
            sortable: true,
        },
        {field: 'userEmail', resizable: true, width: 200, filter: true, sortable: true},
        {field: 'organization', resizable: true, width: 200, filter: true, sortable: true},
        {field: 'creationDate', resizable: true, width: 140, filter: true, sortable: true},
        {field: 'runningDate', resizable: true, width: 140, filter: true, sortable: true},
        {field: 'doneDate', resizable: true, width: 140, filter: true, sortable: true},
        {
            headerName: t('executions.label'),
            cellRenderer: (params: any) => {
                const execution = params.data;
                const executionActions = [
                    {
                        type: ERequestActions.CANCEL,
                        isDisabled: !execution.canCancelExecution,
                        label: t('executions.cancelExecution'),
                        icon: <IconSquareRoundedX size="1rem" color={theme.colors.orange[6]} stroke={1.5} />,
                        onClick: (event: any, executionRequestId: string) => cancelExecutionClicked(executionRequestId),
                    },
                ];
                return <ExecutionActionMenu execution={params.data} executionActions={executionActions} />;
            },
            resizable: true,
            width: 140,
        },
    ];

    return (
        <div>
            <div
                className="ag-theme-alpine"
                style={{marginLeft: 'auto', marginRight: 'auto', width: 'calc(100% - 2rem)'}}
            >
                <ExecutionsSearch onSearch={searchExecutions} />
                <AgGridReact
                    rowBuffer={0}
                    cacheBlockSize={100}
                    enableCellTextSelection={true}
                    columnDefs={columnDefs}
                    domLayout="autoHeight"
                    rowHeight={40}
                    detailRowHeight={40}
                    rowData={rows}
                ></AgGridReact>
            </div>
        </div>
    );
}

export default AdminExecutionsList;
