import {Avatar, Box, Group, Menu, UnstyledButton, useMantineTheme} from '@mantine/core';
import {authActions} from 'src/store/reducers/auth-reducer';
import useTranslation from 'src/i18next.config';
import {useState} from 'react';
import {IconChevronRight, IconGauge, IconLock, IconLogout, IconLogin} from '@tabler/icons-react';
import {useNavigate} from 'react-router-dom';
import {ERoutes} from 'src/types/enum/routes.enum';
import {userProfileActions} from 'src/store/reducers/user-reducer';

function User() {
    const removeToken = authActions.useRemoveToken();
    const removeProfile = userProfileActions.useRemoveProfile();
    const {t} = useTranslation();
    const theme = useMantineTheme();
    const navigate = useNavigate();
    const token = authActions.useToken();
    const userProfile = userProfileActions.useUserProfile();

    const [menuOpened, setMenuOpened] = useState(false);

    async function signOut() {
        await removeToken();
        await removeProfile();
        navigate(ERoutes.SIGN_IN);
    }
    async function goSignIn() {
        navigate(ERoutes.SIGN_IN);
    }

    const buttonEditPassword = (
        <Menu.Item
            onClick={() => {
                navigate(ERoutes.CHANGE_PASSWORD);
            }}
            icon={<IconLock size="1rem" color={theme.colors.blue[6]} stroke={1.5} />}
        >
            {t('editYourPassword')}
        </Menu.Item>
    );

    const buttonSignOut = (
        <Menu.Item onClick={signOut} icon={<IconLogout size="1rem" color={theme.colors.red[6]} stroke={1.5} />}>
            {t('signOut')}
        </Menu.Item>
    );

    const buttonSignIn = (
        <Menu.Item onClick={goSignIn} icon={<IconLogin size="1rem" color={theme.colors.red[6]} stroke={1.5} />}>
            {t('signIn')}
        </Menu.Item>
    );

    const buttonLimits = (
        <Menu.Item
            onClick={() => {
                navigate(ERoutes.LIMITS);
            }}
            icon={<IconGauge size="1rem" color={theme.colors.green[6]} stroke={1.5} />}
        >
            {t('limits')}
        </Menu.Item>
    );

    const userMenu = (
        <Menu.Dropdown>
            {!userProfile.isAdmin && buttonLimits}
            {buttonEditPassword}
            {buttonSignOut}
        </Menu.Dropdown>
    );
    const guestMenu = <Menu.Dropdown>{buttonSignIn}</Menu.Dropdown>;

    let menu;
    if (token) {
        menu = userMenu;
    } else {
        menu = guestMenu;
    }
    return (
        <Box
            sx={{
                borderTop: `1px solid #DDD`,
            }}
        >
            <Menu position="top-end" width={220} withinPortal>
                <Menu.Target>
                    <UnstyledButton
                        sx={{
                            display: 'block',
                            width: '100%',
                            padding: theme.spacing.xs,
                            borderRadius: theme.radius.sm,
                            color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

                            '&:hover': {
                                backgroundColor:
                                    theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                            },
                        }}
                        onClick={() => setMenuOpened(!menuOpened)}
                    >
                        <Group>
                            <Avatar radius="xl" onClick={() => setMenuOpened(!menuOpened)} />
                            <Box>
                                <p>{t('account')}</p>
                            </Box>

                            <IconChevronRight />
                        </Group>
                    </UnstyledButton>
                </Menu.Target>
                {menu}
            </Menu>
        </Box>
    );
}

export default User;
