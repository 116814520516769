import {HttpMutators} from './http-mutators';
import {useHttp} from './http';

export type SignInPayload = {
    email: string;
    password: string;
};

export type requestNewTokenPayload = {
    refreshToken: string;
};

export const useAuthService = () => {
    const {post, get} = useHttp();

    return {
        signIn: (mutators: HttpMutators, payload: SignInPayload) =>
            post(mutators, {
                url: '/sign-in',
                payload,
            }),
        requestNewToken: (mutators: HttpMutators, payload: requestNewTokenPayload) =>
            get(mutators, {
                url: '/access-token',
                payload,
            }),
    };
};
