import {Button, Container, Tooltip} from '@mantine/core';
import {createStyles} from '@mantine/core';
import {IconDownload, IconDownloadOff} from '@tabler/icons-react';
import useTranslation from 'src/i18next.config';
import {useState} from 'react';
import {humanFileSize} from 'src/helpers/file.helper';
import {getTimeLeft} from 'src/helpers/time.helper';

type ExecutionDownloadButtonProps = {
    label: string;
    nbFiles: number;
    size: number;
    isDisabled: boolean;
    showMetadata: boolean;
    cleanUpDate: number | undefined;
    onClick?: (event: any) => void;
};

type TimeLeft = {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
};

export function ExecutionDownloadButton({
    label,
    nbFiles,
    size,
    isDisabled,
    showMetadata,
    cleanUpDate,
    onClick,
}: ExecutionDownloadButtonProps) {
    const {classes} = useStyles();
    const [timeLeft, setTimeLeft] = useState<TimeLeft>();
    const {t} = useTranslation();
    function updateCleanUpTimeLeft() {
        if (cleanUpDate) {
            setTimeLeft(getTimeLeft(cleanUpDate));
        }
    }

    function getTooltipLabel() {
        if (timeLeft) {
            if (timeLeft.seconds >= 0) {
                return `${t('cleanupConfiguration.cleanUpExpectedTime')} ${timeLeft?.days}d ${timeLeft?.hours}h ${
                    timeLeft?.minutes
                }mn ${timeLeft?.seconds}s`;
            } else {
                return t('cleanupConfiguration.cleanUpExpectedSoon');
            }
        } else {
            return t('cleanupConfiguration.noCleanUpExpectedTime');
        }
    }

    let filesInfo = {nbFilesText: '', filesSizeText: ''};
    if (showMetadata) {
        filesInfo = {
            nbFilesText: `${t('files')}: ${nbFiles ? nbFiles : 0}`,
            filesSizeText: `${t('size')}: ${humanFileSize(size)}`,
        };
    }

    return (
        <Tooltip multiline width={300} withArrow transition="fade" transitionDuration={200} label={getTooltipLabel()}>
            <Button
                className={classes.downloadButton}
                leftIcon={isDisabled ? <IconDownloadOff size="1.5rem" /> : <IconDownload size="1.5rem" />}
                color="cyan.8"
                disabled={isDisabled}
                onClick={onClick}
                onMouseOver={updateCleanUpTimeLeft}
            >
                <Container className={classes.container}>
                    <div className={classes.label}>{label}</div>
                    {showMetadata && (
                        <>
                            <div style={{color: isDisabled ? '#9e9b9b' : '#dbdbdb'}} className={classes.metaData}>
                                {filesInfo?.nbFilesText}
                            </div>
                            <div style={{color: isDisabled ? '#9e9b9b' : '#dbdbdb'}} className={classes.metaData}>
                                {filesInfo?.filesSizeText}
                            </div>
                        </>
                    )}
                </Container>
            </Button>
        </Tooltip>
    );
}

const useStyles = createStyles(theme => ({
    downloadButton: {
        width: '-moz-available',
        inlineSize: '-webkit-fill-available',
    },
    container: {padding: '0px'},
    label: {
        top: '-4px',
        fontSize: '1.1em',
    },
    metaData: {
        fontSize: '0.9em',
        fontWeight: 'lighter',
        textShadow: 'none',
        fontFamily: 'verdana, sans-serif',
    },
}));
