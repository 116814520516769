import {showNotification} from '@mantine/notifications';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {Organization} from 'src/types/models/Organization';
import {OrganizationForm} from './OrganizationForm';
import {useOrganizationService} from 'src/services/organization';
import useTranslation from 'src/i18next.config';
import { ERoutes } from 'src/types/enum/routes.enum';

export function UpdateOrganization() {
    const {t} = useTranslation();
    const {patchOrganization, getOrganization} = useOrganizationService();
    const {id} = useParams();
    const navigate = useNavigate();

    const [organization, setOrganization] = useState<Organization>();

    useEffect(() => {
        if (id != null) {
            getOrganization(
                {
                    success: (values: Organization) => {
                        if (values.maxExecutionTime) {
                            values.maxExecutionTime = parseFloat((values.maxExecutionTime / 60).toFixed(2));
                        }
                        if (values.usedExecutionTime) {
                            values.usedExecutionTime = parseFloat((values.usedExecutionTime / 60).toFixed(2));
                        }
                        setOrganization(values);
                    },
                },
                id
            );
        }
    }, []);

    async function updateOrganization(payload: Organization) {
        if (id != null) {
            await patchOrganization(
                {
                    error: () => {
                        showNotification({
                            title: t('organization.editOrganizationNotification'),
                            message: t('organization.editOrganizationErrorMessage'),
                            color: 'red',
                        });
                    },
                    success: () => {
                        showNotification({
                            title: t('organization.editOrganizationNotification'),
                            message: t('organization.editOrganizationSuccessMessage'),
                            color: 'green',
                        });
                        navigate(ERoutes.ADMIN_ORGANIZATIONS);
                    },
                },
                id,
                {
                    ...payload,
                    maxExecutionTime: payload.maxExecutionTime * 60,
                    usedExecutionTime: payload.usedExecutionTime * 60,
                }
            );
        }
    }

    if (organization) {
        return (
            <>
                <OrganizationForm
                    initialOrganization={organization}
                    title={t('organization.editOrganization')}
                    requestButtonLable="update"
                    onSave={updateOrganization}
                ></OrganizationForm>
            </>
        );
    } else {
        return <></>;
    }
}
