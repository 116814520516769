import {createStyles, Paper, Title, Alert, TextInput, Button} from '@mantine/core';
import {useEffect, useState} from 'react';
import {useFormik} from 'formik';

import {authActions} from 'src/store/reducers/auth-reducer';
import useTranslation from 'src/i18next.config';
import {SignInPayload, useAuthService} from 'src/services/authentication';
import {persistor} from 'src/store';
import {useNavigate} from 'react-router-dom';
import {ERoutes} from 'src/types/enum/routes.enum';
import {userProfileActions} from 'src/store/reducers/user-reducer';
import {showNotification} from '@mantine/notifications';

const useStyles = createStyles({
    formContainer: {
        paddingTop: 120,
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 50,
        gap: 20,
    },
});

export const SignIn = () => {

    const authService = useAuthService();
    const {classes} = useStyles();
    const saveToken = authActions.useSaveToken();
    const saveProfile = userProfileActions.useSaveProfile();
    const token = authActions.useToken();
    const {t} = useTranslation();

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>();
    const navigate = useNavigate();

    useEffect(() => {
        persistor.persist();
        setLoading(false);
    }, []);

    useEffect(() => {
        if (token) {
            navigate(ERoutes.ROOT);
        }
    }, []);

    const formik = useFormik<SignInPayload>({
        initialValues: {
            email: '',
            password: '',
        },
        onSubmit: signIn,
    });

    function signIn(signInPayload: SignInPayload) {
        authService.signIn(
            {
                loading: value => setLoading(value),
                error: err => {
                    showNotification({
                        title: t('auth.signInNotification'),
                        message: err.message,
                        color: 'red',
                    });
                },
                success: res => {
                    const {token, refreshToken} = res;
                    saveToken({token, refreshToken});

                    saveProfile(token);

                    navigate(ERoutes.ROOT);
                },
            },
            signInPayload
        );
    }

    return (
        <div>
            <Paper
                p="md"
                shadow="md"
                className={classes.formContainer}
                component="form"
                onSubmit={formik.handleSubmit}
                noValidate
            >
                <Title order={2}>{t('signIn')}</Title>
                <div>
                    <Title order={6}>{t('email')}</Title>
                    <TextInput
                        id="email"
                        type="email"
                        autoComplete="on"
                        placeholder={t('emailPlaceholder').toString()}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        error={!!formik.touched.email && !!formik.errors.email && formik.errors.email}
                    />
                </div>
                <div>
                    <Title order={6}>{t('password')}</Title>
                    <TextInput
                        id="password"
                        type="password"
                        placeholder={t('password').toString()}
                        autoComplete="on"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        error={!!formik.touched.password && !!formik.errors.password && formik.errors.password}
                    />
                </div>
                <Button color="cyan.8" type="submit" loading={loading}>
                    {t('signIn')}
                </Button>
            </Paper>
            {errorMessage && (
                <Alert color="red" radius="lg" variant="filled">
                    {errorMessage}
                </Alert>
            )}
        </div>
    );
};

export default SignIn;
