import {Button, Group, Modal, Text, Title} from '@mantine/core';
import {Dispatch, SetStateAction} from 'react';
import {useTranslation} from 'react-i18next';

type ConfirmationModalProps = {
    openModal?: (setIsOpen: Dispatch<SetStateAction<boolean>>) => void;
    isOpen: boolean;
    title: string;
    text: string;
    onClose: () => void;
    onConfirmation: () => void;
};
export default function ConfirmationModal({isOpen, title, text, onClose, onConfirmation}: ConfirmationModalProps) {
    const {t} = useTranslation();

    return (
        <Modal
            opened={isOpen}
            onClose={() => onClose()}
            title={<Title order={3}>{title}</Title>}
            size="md"
            radius="lg"
            closeOnClickOutside={true}
            withCloseButton={false}
        >
            <Text>{text}</Text>
            <Group position="right">
                <Button onClick={() => onConfirmation()} variant="filled">
                    {t('modal.ok')}
                </Button>

                <Button onClick={() => onClose()} variant="subtle">
                    {t('modal.cancel')}
                </Button>
            </Group>
        </Modal>
    );
}
