import {showNotification} from '@mantine/notifications';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import { ToS } from 'src/types/models/ToS';
import { ToSForm } from './ToSForm';
import useTosService from 'src/services/tos';
import useTranslation from 'src/i18next.config';
import {ERoutes} from 'src/types/enum/routes.enum';

export function UpdateToS() {
    const {t} = useTranslation();
    const {patchTos, getTos} = useTosService();
    const {id} = useParams();
    const navigate = useNavigate();

    const [tos, setToS] = useState<ToS>();

    useEffect(() => {
        if (id != null) {
            getTos(
                {
                    success: (values: ToS) => {
                        setToS(values);
                    },
                },
                id
            );
        }
    }, []);

    async function updateToS(payload: ToS) {
        if (id != null) {
            await patchTos(
                {
                    error: () => {
                        showNotification({
                            title: t('tos.editToSNotification'),
                            message: t('tos.editToSErrorMessage'),
                            color: 'red',
                        });
                    },
                    success: () => {
                        showNotification({
                            title: t('tos.editToSNotification'),
                            message: t('tos.editToSSuccessMessage'),
                            color: 'green',
                        });
                        navigate(ERoutes.ADMIN_TOS);
                    },
                },
                id,
                {
                    ...payload
                }
            );
        }
    }

    if (tos) {
        return (
            <>
                <ToSForm
                    initialToS={tos}
                    title={t('tos.editToS')}
                    submitBtnLbl="update"
                    onSave={updateToS}
                ></ToSForm>
            </>
        );
    } else {
        return <></>;
    }
}
