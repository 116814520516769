import {
    IconBuildingSkyscraper,
    IconFileDescription,
    IconFileShredder,
    IconList,
    IconRobot,
    IconUsersGroup,
} from '@tabler/icons-react';
import {Navbar} from '@mantine/core';

import { ERoutes } from 'src/types/enum/routes.enum';
import {MainLinkProps} from 'src/types/interfaces/main-link';
import {MainLinks} from './_mainLinks';
import User from './_user';
import {userProfileActions} from 'src/store/reducers/user-reducer';

const adminLinks: MainLinkProps[] = [
    {icon: <IconList size="1rem" />, color: 'blue', label: 'Executions', path: ERoutes.ADMIN_EXECUTIONS},
    {icon: <IconFileShredder size="1rem" />, color: 'orange', label: 'Files Cleanup Config', path: ERoutes.ADMIN_FILES_CONFIG},
    {
        icon: <IconBuildingSkyscraper size="1rem" />,
        color: 'green',
        label: 'Organizations',
        path: ERoutes.ADMIN_ORGANIZATIONS,
    },
    {icon: <IconFileDescription size="1rem" />, color: 'violet', label: 'Terms Of Service', path: ERoutes.ADMIN_TOS},
    {icon: <IconUsersGroup size="1rem" />, color: 'violet', label: 'Users', path: ERoutes.USERS},
    {icon: <IconRobot size="1rem" />, color: 'violet', label: 'Agents', path: ERoutes.ADMIN_AGENTS},
];

const userLinks: MainLinkProps[] = [
    {icon: <IconList size="1rem" />, color: 'blue', label: 'Executions', path: ERoutes.EXECUTIONS},
    {icon: <IconFileDescription size="1rem" />, color: 'violet', label: 'Terms Of Service', path: ERoutes.USER_READ_TOS},
    /*  {
        icon: <IconArtboard size="1rem" />,
        color: 'green',
        label: 'Builder',
        path: '/builder',
    }, */
];

const organizationManagerLinks: MainLinkProps[] = [
    {icon: <IconList size="1rem" />, color: 'blue', label: 'Executions', path: ERoutes.EXECUTIONS},
    {icon: <IconFileDescription size="1rem" />, color: 'violet', label: 'Terms Of Service', path: ERoutes.TOS},
    /* {
        icon: <IconArtboard size="1rem" />,
        color: 'green',
        label: 'Builder',
        path: '/builder',
    }, */
    {icon: <IconUsersGroup size="1rem" />, color: 'violet', label: 'Users', path: ERoutes.USERS},
];

export function AppNavbar() {
    const userProfile = userProfileActions.useUserProfile();

    function displayAdminLinks() {
        return <MainLinks links={adminLinks} />;
    }

    function displayUserLinks() {
        return <MainLinks links={userLinks} />;
    }

    function displayOrganizationManagerLinks() {
        return <MainLinks links={organizationManagerLinks} />;
    }

    return (
        <Navbar width={{base: 220}}>
            <Navbar.Section grow mt="md">
                {userProfile.isAdmin && displayAdminLinks()}
                {userProfile.isUser && displayUserLinks()}
                {userProfile.isOrganizationManager && displayOrganizationManagerLinks()}
            </Navbar.Section>
            <Navbar.Section mt="md">
                <User />
            </Navbar.Section>
        </Navbar>
    );
}
