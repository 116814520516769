import {Button, Paper, Select, TextInput, Title} from '@mantine/core';
import {useFormik} from 'formik';
import useTranslation from 'src/i18next.config';
import {UpdateUserPayload, useUserService} from 'src/services/user';
import {useEffect, useState} from 'react';
import {ERoles} from 'src/types/enum/roles.enum';
import {showNotification} from '@mantine/notifications';
import {useLocation, useNavigate} from 'react-router-dom';
import { ERoutes } from 'src/types/enum/routes.enum';
import { userProfileActions } from 'src/store/reducers/user-reducer';

export function UpdateUser() {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const userService = useUserService();
    const userToUpdate = useLocation().state.userToUpdate;

    const [formCompleted, setFormCompleted] = useState(false);
    const [eRoles, setEroles] = useState(Object.values(ERoles));
    const [userRole, setUserRole] = useState(userToUpdate.role);
    const userProfile = userProfileActions.useUserProfile();

    const formik = useFormik<UpdateUserPayload>({
        initialValues: {
            firstName: userToUpdate.firstName,
            lastName: userToUpdate.lastName,
            description: userToUpdate.description,
            role: userRole,
        },
        onSubmit: updateUserClicked,
    });

    useEffect(() => {
        // organization manager can't set an user with an admin role
        function filterRoles() {
            if (!userProfile.isAdmin) {
                const newERoles = eRoles.filter(role => role !== ERoles.ADMIN)
                setEroles(newERoles);
            }
        }
        filterRoles();
    }, [eRoles, userProfile]);

    useEffect(() => {
        if (!formCompleted) {
            const {firstName, lastName, role} = formik.values;
            if (firstName && lastName && role) {
                setFormCompleted(true);
            }
        }
    }, [formik]);

    async function updateUserClicked(updateUserPayload: UpdateUserPayload) {
        if (!formCompleted) {
            showNotification({
                title: t('formNotCompleted'),
                message: t('formNotCompletedMessage'),
                color: 'red',
            });
        } else {
            await userService.updateUser(
                {
                    error: err => {
                        if (err.message) {
                            showNotification({
                                message: err.message,
                                color: 'red',
                            });
                        } else {
                            showNotification({
                                title: t('user.editUserNotification'),
                                message: t('user.editUserErrorMessage'),
                                color: 'red',
                            });
                        }
                    },
                    success: () => {
                        showNotification({
                            title: t('user.editUserNotification'),
                            message: t('user.editUserSuccessMessage'),
                            color: 'green',
                        });
                        setFormCompleted(false);
                        navigate(ERoutes.USERS);
                    },
                },
                userToUpdate._id,
                updateUserPayload
            );
        }
    }

    function displayRoleInput() {
        return (
            <div style={{marginTop: 10}}>
                <Title order={6}>{t('role')}</Title>
                <Select
                    id="role"
                    required={true}
                    placeholder={t('role').toString()}
                    onChange={value => {
                        setUserRole(value as ERoles);
                        formik.setFieldValue('role', value);
                    }}
                    data={eRoles.map(role => {
                        return {
                            value: role,
                            label: t(`roles.${role}`).toString(),
                        };
                    })}
                    value={userRole}
                    error={!!formik.touched.role && !!formik.errors.role && formik.errors.role}
                />
            </div>
        );
    }

    return (
        <Paper
            p="md"
            shadow="md"
            component="form"
            onSubmit={formik.handleSubmit}
            noValidate
            style={{minWidth: '500px', width: '50%', margin: 'auto'}}
        >
            <Title order={2}>{t('user.editUser')}</Title>
            <div style={{marginTop: 20}}>
                <Title order={6}>{t('firstName')}</Title>
                <TextInput
                    id="firstName"
                    type="text"
                    required={true}
                    placeholder={t('firstName').toString()}
                    onChange={formik.handleChange}
                    value={formik.values.firstName}
                    error={!!formik.touched.firstName && !!formik.errors.firstName && formik.errors.firstName}
                />
            </div>
            <div style={{marginTop: 10}}>
                <Title order={6}>{t('lastName')}</Title>
                <TextInput
                    id="lastName"
                    type="text"
                    required={true}
                    placeholder={t('lastName').toString()}
                    onChange={formik.handleChange}
                    value={formik.values.lastName}
                    error={!!formik.touched.lastName && !!formik.errors.lastName && formik.errors.lastName}
                />
            </div>

            <div style={{marginTop: 10}}>
                <Title order={6}>{t('email')}</Title>
                <TextInput
                    disabled
                    id="email"
                    type="text"
                    placeholder={t('email').toString()}
                    value={userToUpdate.email}
                />
            </div>

            <div style={{marginTop: 10}}>
                <Title order={6}>{t('organization.label')}</Title>
                <TextInput
                    disabled
                    id="organization"
                    type="text"
                    placeholder={t('organization.label').toString()}
                    value={userToUpdate.organization.name}
                />
            </div>
            <div style={{marginTop: 10}}>
                <Title order={6}>{t('description')}</Title>
                <TextInput
                    id="description"
                    type="text"
                    placeholder={t('description').toString()}
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    error={!!formik.touched.description && !!formik.errors.description && formik.errors.description}
                />
            </div>
            {displayRoleInput()}

            <Button color="cyan.8" type="submit" style={{marginTop: 20}}>
                {t('update')}
            </Button>
        </Paper>
    );
}
