import useTranslation from 'src/i18next.config';
import {useOrganizationService} from 'src/services/organization';
import {showNotification} from '@mantine/notifications';
import {OrganizationForm} from './OrganizationForm';
import {useNavigate} from 'react-router-dom';
import {ERoutes} from 'src/types/enum/routes.enum';
import {Organization} from 'src/types/models/Organization';

export function AddOrganization() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {createOrganization} = useOrganizationService();

    async function addOrganization(payload: Organization) {
        await createOrganization(
            {
                error: () => {
                    showNotification({
                        title: t('organization.addNewOrganizationNotification'),
                        message: t('organization.addNewOrganizationErrorMessage'),
                        color: 'red',
                    });
                },
                success: () => {
                    showNotification({
                        title: t('organization.addNewOrganizationNotification'),
                        message: t('organization.addNewOrganizationSuccessMessage'),
                        color: 'green',
                    });
                    navigate(ERoutes.ADMIN_ORGANIZATIONS);
                },
            },
            {
                ...payload,
                maxExecutionTime: payload.maxExecutionTime * 60,
                usedExecutionTime: payload.usedExecutionTime * 60,
            }
        );
    }

    return (
        <>
            <OrganizationForm
                onSave={addOrganization}
                title={t('organization.addNewOrganization')}
                requestButtonLable="create"
            ></OrganizationForm>
        </>
    );
}
