import {Action} from './action';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from './root-reducer';
import {Reducer} from 'redux';
import {EAuthActionType} from '../../types/enum/auth-action-type.enum';

export type AuthState = {
    token: string | null;
    refreshToken: string | null;
};

const initialState: AuthState = {
    token: null,
    refreshToken: null,
};

export type AuthActionPayload = {
    token: string | null;
    refreshToken: string | null;
};

export interface AuthAction extends Action {
    type: EAuthActionType;
    payload: AuthActionPayload;
}

const useRefreshToken = (): string | null => {
    return useSelector<RootState, string | null>(state => state.authReducer.refreshToken);
};

const useRemoveToken = () => {
    const dispatch = useDispatch();
    return () => dispatch({type: EAuthActionType.removeToken});
};

const useSaveToken = () => {
    const dispatch = useDispatch();
    return (res: any) => {
        // ! compensating for the weird behavior of `redux-persist` on reload
        // TODO review redux implementation
        localStorage.setItem('accessToken', res.token);
        localStorage.setItem('refreshToken', res.refreshToken);
        return dispatch({type: EAuthActionType.saveToken, payload: res})
    };
};

const useToken = (): string | null => {
    return useSelector<RootState, string | null>(state => {
        return state.authReducer.token
    });
};


export const authActions = {useToken, useRefreshToken, useSaveToken, useRemoveToken}

export const authReducer: Reducer<AuthState, AuthAction> = (state = initialState, action: AuthAction) => {
    switch (action.type) {
        case EAuthActionType.saveToken:
            return {
                ...state,
                ...action.payload
            };
        case EAuthActionType.removeToken:
            return {...state, ...initialState};
        default:
            return state;
    }
};
