import {useEffect, useState} from 'react';
import {FilesCleanUpConfigType, ExecutionsPaginationType} from 'src/types';
import {Execution} from 'src/types/models/Execution';
import {useExecutionService} from 'src/services';
import {Paper, Table, useMantineTheme} from '@mantine/core';
import useTranslation from 'src/i18next.config';

import { authActions } from 'src/store/reducers/auth-reducer';
import {formatDate} from 'src/helpers/format-date';
import {EFilesType} from 'src/types/enum/files-type.enum';
import {ELocalesFormats} from 'src/types/enum/locales-formats.enum';
import {ERequestActions} from 'src/types/enum/request-actions.enum';
import {ExecutionStatusBadge} from './ExecutionStatusBadge';
import {ExecutionDownloadButton} from './ExecutionDownloadButton';
import {ExecutionActionMenu} from './ExecutionActionMenu';
import {IconSquareRoundedX, IconTrash} from '@tabler/icons-react';
import {userProfileActions} from 'src/store/reducers/user-reducer';
import {IGetExecutionsPaginationPayload} from 'src/services/execution';
import {ExecutionPagination} from './ExecutionPagination';
import ExecutionsSearch from './ExecutionsSearch';
import ExecutionsSearchQs from 'src/types/interfaces/executions-search-sq';
import {showNotification} from '@mantine/notifications';
import { ERequestStatus } from 'src/types/enum/request-status.enum';

const INITIAL_PAGE = 1;
const INITIAL_PAGE_LIMIT = 9;

export function ExecutionsList() {

    const executionService = useExecutionService();
    const {t} = useTranslation();
    const theme = useMantineTheme();
    const token = authActions.useToken();

    const [executions, setExecutions] = useState<Execution[]>([]);
    const [executionsPagination, setExecutionsPagination] = useState<ExecutionsPaginationType>();
    const [filesCleanUpConfig, setFilesCleanUpConfig] = useState<FilesCleanUpConfigType>();
    const userProfile = userProfileActions.useUserProfile();

    useEffect(function () {
        if (executions.length < 1) {
            getExecutions(INITIAL_PAGE, INITIAL_PAGE_LIMIT);
        }
    }, []);

    function cancelExecutionClicked(executionId: string) {
        executionService.postStopExecution(
            {
                error: error => {
                    showNotification({
                        title: t('errorCancelingExecution'),
                        message: t('anErrorOccurredPleaseRetryLater'),
                        color: 'red',
                    });
                },
                success: res => {
                    showNotification({
                        title: t('stopExecutionRequestSent'),
                        message: t('stopExecutionRequestSent'),
                        color: 'green',
                    });
                    // set the new status of the execution in the execution list
                    const newExecutions = executions.map(exec => {
                        if (exec._id === executionId) {
                            exec.status = ERequestStatus.CANCELED;
                        }
                        return exec;
                    });
                    setExecutions(newExecutions);
                },
            },
            {executionId}
        );
    }

    function downloadClicked(id: string, index = 0, source: string) {
        executionService.getResultsFiles(
            {
                error: error => {
                    showNotification({
                        title: t('errorFetchingData'),
                        message: t('anErrorOccurredPleaseRetryLater'),
                        color: 'red',
                    });
                },
                success: res => {
                    const data = res.data;
                    if (data === -1 || data === '-1') {
                        return;
                    } else {
                        const blob = new Blob([data]);
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        const filename = res.headers.get('content-disposition')?.split(';')[1]?.split('=')[1];
                        if (filename) {
                            link.download = JSON.parse(filename) || 'results';
                            link.click();
                            link.remove();
                            link.parentElement?.removeChild(link);
                            downloadClicked(id, index + 1, source);
                        }
                    }
                },
            },
            {executionId: id, index: index, source}
        );
    }

    function deleteFilesClicked(executionId: string) {
        executionService.postDeleteExecutionFiles(
            {
                error: error => {
                    showNotification({
                        title: t('errorDeletingExecutionFiles'),
                        message: t('anErrorOccurredPleaseRetryLater'),
                        color: 'red',
                    });
                },
                success: res => {
                    showNotification({
                        title: t('deleteFilesRequestSent'),
                        message: t('deleteFilesRequestSent'),
                        color: 'green',
                    });
                },
            },
            {executionId}
        );
    }

    async function getExecutions(page: number, limit: number) {
        await executionService.getPaginatedExecutions(
            {
                error: error => {
                    showNotification({
                        title: t('errorFetchingData'),
                        message: t('errorFetchingDataMessage'),
                        color: 'red',
                    });
                },
                success: res => {
                    setExecutions(res.executions.docs);
                    setFilesCleanUpConfig(res.config);
                    setExecutionsPagination({
                        ...res.executions,
                        docs: null,
                    });
                },
            },
            {
                page,
                limit,
            } as IGetExecutionsPaginationPayload
        );
    }

    function getExecutionRows(executions: Execution[]) {
        return executions.map((exec: Execution) => {
            let creationDate: string | null = null;
            let runningDate: string | null = null;
            let doneDate: string | null = null;
            if (exec.creationDate) {
                creationDate = formatDate(new Date(exec.creationDate), ELocalesFormats.FR);
            }
            if (exec.runningDate) {
                runningDate = formatDate(new Date(exec.runningDate), ELocalesFormats.FR);
            }
            if (exec.doneDate) {
                doneDate = formatDate(new Date(exec.doneDate), ELocalesFormats.FR);
            }

            const executionActions = [
                {
                    type: ERequestActions.CANCEL,
                    isDisabled: !exec.canCancelExecution,
                    label: t('executions.cancelExecution'),
                    icon: <IconSquareRoundedX size="1rem" color={theme.colors.orange[6]} stroke={1.5} />,
                    onClick: (event: any, executionRequestId: string) => cancelExecutionClicked(executionRequestId),
                },
                {
                    type: ERequestActions.DELETE_FILES,
                    isDisabled: !exec.canDeleteFiles,
                    label: t('executions.deleteFiles'),
                    icon: <IconTrash size="1rem" color={theme.colors.red[6]} stroke={1.5} />,
                    onClick: (event: any, executionRequestId: string) => deleteFilesClicked(executionRequestId),
                },
            ];

            function getCleanUpDate(
                doneDate: number | null,
                downloadDate: number | null,
                daysBeforeDeletionForDownload: number | undefined
            ): number | undefined {
                const msBeforeCleanUpForDone =
                    1000 *
                    60 *
                    60 *
                    24 *
                    (filesCleanUpConfig?.daysBeforeDeletionForDone ? filesCleanUpConfig.daysBeforeDeletionForDone : 0);
                const msBeforeCleanUpForDownload =
                    1000 * 60 * 60 * 24 * (daysBeforeDeletionForDownload ? daysBeforeDeletionForDownload : 0);

                if (!doneDate && !downloadDate) {
                    return undefined;
                }
                if (downloadDate && doneDate) {
                    if (downloadDate < doneDate) {
                        return downloadDate + msBeforeCleanUpForDone;
                    } else {
                        return doneDate + msBeforeCleanUpForDownload;
                    }
                } else {
                    if (downloadDate) {
                        return downloadDate + msBeforeCleanUpForDownload;
                    }
                    if (doneDate) {
                        return doneDate + msBeforeCleanUpForDone;
                    }
                }
            }

            function getDownloadLabel(execution: Execution, filesType: string): string {
                let execFilesInfo;
                if (filesType === EFilesType.UPLOADS) {
                    execFilesInfo = {
                        numberOfFiles: execution.numberOfUploadedFiles,
                        hasDeletedFiles: execution.hasDeletedUploadFiles,
                    };
                } else {
                    execFilesInfo = {
                        numberOfFiles: execution.numberOfResultedFiles,
                        hasDeletedFiles: execution.hasDeletedResultFiles,
                    };
                }

                if (execFilesInfo.hasDeletedFiles) {
                    return t('cleanupConfiguration.filesDeleted');
                }
                if (!execFilesInfo.numberOfFiles || execFilesInfo.numberOfFiles < 1) {
                    return t('cleanupConfiguration.noFiles');
                }

                return '';
            }

            return (
                <tr key={exec._id}>
                    <td>{exec.label}</td>
                    <td>{exec.name}</td>
                    <td>
                        <ExecutionStatusBadge status={exec.status} errorMessage={exec.errorMessage} />
                    </td>
                    <td>{exec._id}</td>
                    {userProfile.isOrganizationManager && (
                        <>
                            <td>{exec.user ? `${exec.user.firstName} ${exec.user.lastName}` : ''}</td>
                            <td>{exec.user ? exec.user.email : ''}</td>
                        </>
                    )}
                    <td style={{fontSize: '0.8rem'}}>{creationDate ? creationDate : ''}</td>
                    <td style={{fontSize: '0.8rem'}}>{runningDate ? runningDate : ''}</td>
                    <td style={{fontSize: '0.8rem'}}>{doneDate ? doneDate : ''}</td>
                    <td>
                        <ExecutionDownloadButton
                            label={getDownloadLabel(exec, EFilesType.UPLOADS)}
                            nbFiles={exec.numberOfUploadedFiles}
                            size={exec.weightOfUploadedFiles}
                            isDisabled={!exec.canDownloadUploadFiles}
                            showMetadata={exec.showUploadedFilesMetadata}
                            cleanUpDate={getCleanUpDate(
                                exec.doneDate,
                                exec.downloadUploadedFilesDate,
                                filesCleanUpConfig?.daysBeforeDeletionForDownloadUploadedFiles
                            )}
                            onClick={e => {
                                e.preventDefault();
                                downloadClicked(exec._id, 0, EFilesType.UPLOADS);
                            }}
                        />
                    </td>
                    <td>
                        <ExecutionDownloadButton
                            label={getDownloadLabel(exec, EFilesType.RESULTS)}
                            nbFiles={exec.numberOfResultedFiles}
                            size={exec.weightOfResultedFiles}
                            isDisabled={!exec.canDownloadResultFiles}
                            showMetadata={exec.showResultedFilesMetadata}
                            cleanUpDate={getCleanUpDate(
                                exec.doneDate,
                                exec.downloadResultedFilesDate,
                                filesCleanUpConfig?.daysBeforeDeletionForDownloadResultedFiles
                            )}
                            onClick={e => {
                                e.preventDefault();
                                downloadClicked(exec._id, 0, EFilesType.RESULTS);
                            }}
                        />
                    </td>
                    <td>
                        <ExecutionActionMenu execution={exec} executionActions={executionActions} />
                    </td>
                </tr>
            );
        });
    }

    async function onPaginationChange(page: number) {
        await getExecutions(page, INITIAL_PAGE_LIMIT);
    }

    async function searchExecutions(search: ExecutionsSearchQs) {
        try {
            const qs = new URLSearchParams(search as any).toString();
            const searchCall = await fetch(`${process.env.REACT_APP_API_URL}/request?${qs}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            const res = await searchCall.json();
            setExecutions(res.executions.docs);
            setFilesCleanUpConfig(res.config);
            setExecutionsPagination({
                ...res.executions,
                docs: null,
            });
        } catch (error) {
            console.error(error);
            showNotification({
                title: t('errorFetchingData'),
                message: t('executions.errorFethingExecutions'),
                color: 'red',
            });
        }
    }

    return (
        <>
            <Paper shadow="md" style={{width: 'fit-content', margin: 'auto'}}>
                <ExecutionsSearch onSearch={searchExecutions} orgRestriction={true} userRestriction={userProfile.isUser} />
                <Table>
                    <thead>
                        <tr>
                            <th>{t('Label')}</th>
                            <th>{t('Name')}</th>
                            <th>{t('status')}</th>
                            <th>{'ID'}</th>
                            {userProfile.isOrganizationManager && (
                                <>
                                    <th style={{minWidth: '160px'}}>{t('userName')}</th>
                                    <th style={{minWidth: '180px'}}>{t('userEmail')}</th>
                                </>
                            )}
                            <th style={{minWidth: '135px'}}>{t('creationDate')}</th>
                            <th style={{minWidth: '135px'}}>{t('runningDate')}</th>
                            <th style={{minWidth: '135px'}}>{t('doneDate')}</th>
                            <th style={{minWidth: '100px'}}>{t('downloadUploadedFiles')}</th>
                            <th style={{minWidth: '100px'}}>{t('downloadResultedFiles')}</th>
                            <th>{t('executions.label')}</th>
                        </tr>
                    </thead>
                    <tbody>{executions && getExecutionRows(executions)}</tbody>
                </Table>
                {executionsPagination && (
                    <ExecutionPagination
                        executionsPagination={executionsPagination}
                        onPaginationChange={onPaginationChange}
                    />
                )}
            </Paper>
        </>
    );
}

export default ExecutionsList;
