import {Paper, Pagination} from '@mantine/core';
import {createStyles} from '@mantine/core';
import {ExecutionsPaginationType} from 'src/types';

type ExecutionPaginationProps = {
    executionsPagination: ExecutionsPaginationType;
    onPaginationChange?: (page: number) => void;
};

export function ExecutionPagination({executionsPagination, onPaginationChange}: ExecutionPaginationProps) {
    const {classes} = useStyles();

    return (
        <Paper shadow="md" className={classes.container}>
            <Pagination total={executionsPagination.totalPages} onChange={onPaginationChange} />
        </Paper>
    );
}

const useStyles = createStyles(theme => ({
    container: {
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '0.75rem',
        paddingBottom: '0.75rem',
    },
}));
