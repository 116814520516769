import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {PersistGate} from 'redux-persist/integration/react';
import App from './App';
import {store, persistor} from './store';
import {NotificationsProvider} from '@mantine/notifications';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <NotificationsProvider>
                    <App />
                </NotificationsProvider>
            </BrowserRouter>
        </PersistGate>
    </Provider>
);
