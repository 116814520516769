import {HttpMutators} from './http-mutators';
import {useHttp} from './http';

export interface IGetExecutionsPaginationPayload {
    page: number;
    limit: number;
}

export type getResultsFilesPayload = {
    executionId: string;
    index: number;
    source: string;
};
export type postStopExecutionPayload = {
    executionId: string;
};

export type postDeleteExecutionPayload = {
    executionId: string;
};

export const useExecutionService = () => {
    const {get, post} = useHttp();

    return {
        getExecutions: (mutators: HttpMutators) => get(mutators, {url: '/request'}),
        getPaginatedExecutions: (mutators: HttpMutators, payload: IGetExecutionsPaginationPayload) =>
            get(mutators, {url: `/request?page=${payload.page}&limit=${payload.limit}`}),
        postStopExecution: (mutators: HttpMutators, payload: postStopExecutionPayload) =>
            post(mutators, {url: `/stop-execution/${payload.executionId}`}),
        postDeleteExecutionFiles: (mutators: HttpMutators, payload: postDeleteExecutionPayload) =>
            post(mutators, {url: `/delete-files/${payload.executionId}`}),
        getResultsFiles: (mutators: HttpMutators, payload: getResultsFilesPayload) =>
            get(mutators, {
                url: `/files/${payload.executionId}/${payload.index}?source=${payload.source}`,
                responseType: 'blob',
            }),
    };
};
