import { createStyles } from "@mantine/core";

import { ERibbonColors } from "src/types/enum/ribbon-colors-enum";

const useStyles = createStyles({
    closeButton: {
        position: "absolute",
        top: "0",
        right: "0",
        padding: "10px",
    },
    topRibbon: {
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
        height: "40px",
        color: "white",
        fontSize: "16px",
        padding: "10px,",
        zIndex: 100,
        textAlign: "center",

    },
    topRibbonDanger: {
        backgroundColor: "#ff0000",
    },
    topRibbonWarning: {
        backgroundColor: "#f28f8a",
    },
});

interface TopRibbonProps {
    color: ERibbonColors;
    closable: boolean;
    message: string;
    onClose: () => void;
    shown: boolean;
}

const TopRibbon = ({closable, color, message, onClose, shown}: TopRibbonProps) => {

    const {classes} = useStyles();
    const colorClass = color == "danger" ? classes.topRibbonDanger : color == "warning" ? classes.topRibbonWarning : '';

    if (shown) {
        return <div className={`${classes.topRibbon} ${colorClass}`}>
            <p>{message}</p>
            <button className={classes.closeButton} onClick={onClose}>
                X
            </button>
        </div>
    } else {
        return null;
    }
};

export default TopRibbon;