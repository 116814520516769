import {HttpMutators} from './http-mutators';
import {useHttp} from './http';

export type EditConfigPayload = {
    daysBeforeDeletionForDone: number;
    daysBeforeDeletionForDownloadUploadedFiles: number;
    daysBeforeDeletionForDownloadResultedFiles: number;
};

export const useConfigService = () => {
    const {get, patch} = useHttp('/config');

    return {
        getConfig: (mutators: HttpMutators) => get(mutators),
        updateConfig: (mutators: HttpMutators, payload: EditConfigPayload) => patch(mutators, {payload}),
    };
};
