import {Button, Modal, Paper, PasswordInput, Table, Title} from '@mantine/core';
import useTranslation from 'src/i18next.config';
import {useUserService} from 'src/services';
import {useEffect, useState} from 'react';
import {CanDeleteUserResponseType} from 'src/types';
import {User} from 'src/types/models/User';
import {showNotification} from '@mantine/notifications';
import {useNavigate} from 'react-router-dom';
import {ERoutes} from 'src/types/enum/routes.enum';
import {userProfileActions} from 'src/store/reducers/user-reducer';
import {ERoles} from 'src/types/enum/roles.enum';
import ConfirmationModal from '../../core/ConfirmationModals';

export function UsersList() {
    const {t} = useTranslation();
    const userService = useUserService();

    const [errorMessage, setErrorMessage] = useState<string | null>();
    const [users, setUsers] = useState<User[]>();
    const [userToEdit, setUserToEdit] = useState<User>();
    const [input, setInput] = useState('');
    const userProfile = userProfileActions.useUserProfile();
    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState(t('areYouSure') as string);
    const [selectedUserId, setSelectedUserId] = useState('');

    useEffect(() => {
        userService
            .getUsers({
                error: error => {
                    console.error(error);
                },
                success: res => {
                    return res.data;
                },
            })
            .then((users: User[]) => {
                setUsers(users);
            });
    }, []);

    const usersArray = users ? users : [];

    const rows = usersArray.map((user: User) => (
        <tr key={user._id}>
            <td>{user.firstName}</td>
            <td>{user.lastName}</td>
            <td>{user.email}</td>
            <td>{user.organization?.name}</td>
            <td>{t(`roles.${user.role}`)}</td>
            <td>{user.description}</td>
            <td>
                <Button
                    disabled={user.role === ERoles.ADMIN && !userProfile.isAdmin}
                    color="cyan.8"
                    variant="outline"
                    onClick={() => {
                        const newUserSelected = user === userToEdit;
                        setUserToEdit(user);
                        setIsPasswordModalOpen(true);
                    }}
                >
                    {t('user.editUserPasswordBtn')}
                </Button>
            </td>
            <td>
                <Button
                    disabled={user.role === ERoles.ADMIN && !userProfile.isAdmin}
                    variant="outline"
                    onClick={() => {
                        navigate(ERoutes.UPDATE_USER, {
                            state: {userToUpdate: user},
                        });
                    }}
                >
                    {t('user.editUser')}
                </Button>
            </td>
            <td>
                <Button
                    disabled={user.role === ERoles.ADMIN && !userProfile.isAdmin}
                    color="red"
                    variant="outline"
                    onClick={() => {
                        setSelectedUserId(user._id);

                        userService.getCanDeleteUser(
                            {
                                error: error => {
                                    showNotification({
                                        title: t('user.deleteUserNotification'),
                                        message: error ? error.message : t('user.deleteUserErrorMessage'),
                                        color: 'red',
                                    });
                                },
                                success: (canDeleteUserResponse: CanDeleteUserResponseType) => {
                                    if (!canDeleteUserResponse.canDelete) {
                                        setModalMessage(canDeleteUserResponse.message);
                                    }
                                    setIsModalOpen(true);
                                },
                            },
                            user._id
                        );
                    }}
                >
                    {t('user.deleteUserBtn')}
                </Button>
            </td>
        </tr>
    ));

    function changePassword() {
        setIsPasswordModalOpen(false);
        userService.updateUserPassword(
            {
                error: err => {
                    showNotification({
                        title: t('user.editUserPasswordNotification'),
                        message: err.message ? err.message : t('user.editUserPasswordErrorMessage'),
                        color: 'red',
                    });
                },
                success: () => {
                    showNotification({
                        title: t('user.editUserPasswordNotification'),
                        message: t('user.editUserPasswordSuccessMessage'),
                        color: 'green',
                    });
                },
            },
            userToEdit?._id || '',
            {password: input}
        );
        setUserToEdit(undefined);
        setInput('');
    }

    function deleteUser(id: string) {
        userService.deleteUser(
            {
                error: error => {
                    showNotification({
                        title: t('user.deleteUserNotification'),
                        message: error ? error.message : t('user.deleteUserErrorMessage'),
                        color: 'red',
                    });
                },
                success: () => {
                    showNotification({
                        title: t('user.deleteUserNotification'),
                        message: t('user.deleteUserSuccessMessage'),
                        color: 'green',
                    });
                    setUsers(users?.filter(user => user._id != id));
                },
            },
            id
        );
    }

    function onModalClose() {
        setIsModalOpen(false);
    }

    function onModalConfirmation() {
        setIsModalOpen(false);
        deleteUser(selectedUserId);
    }

    return (
        <>
            <Button
                color="cyan.8"
                type="submit"
                style={{marginBottom: 20}}
                onClick={() => {
                    navigate(ERoutes.ADD_USER);
                }}
            >
                {t('user.addUserBtn')}
            </Button>
            <Paper shadow="md" style={{width: 'fit-content', margin: 'auto'}}>
                <Table>
                    <thead>
                        <tr>
                            <th style={{minWidth: '100px'}}>{t('firstName')}</th>
                            <th style={{minWidth: '100px'}}>{t('lastName')}</th>
                            <th>{t('email')}</th>
                            <th>{t('organization.label')}</th>
                            <th style={{minWidth: '140px'}}>{t('role')}</th>
                            <th style={{minWidth: '10px'}}>{t('description')}</th>
                            <th>{}</th>
                            <th>{}</th>
                        </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </Table>
            </Paper>
            <Modal
                opened={isPasswordModalOpen}
                title={`${t('user.editUserPasswordBtn')} - ${userToEdit?.firstName} ${userToEdit?.lastName}`}
                onClose={() => {setIsPasswordModalOpen(false)}}>
                    <div
                        style={{
                            width: 300,
                            height: 'auto',
                            padding: 20,
                            alignItems: 'center',
                        }}
                    >
                        <Title order={3}>{t('enterNewPassword')}</Title>

                        <PasswordInput
                            id="password"
                            placeholder={t('newPassword').toString()}
                            onChange={event => setInput(event.target.value)}
                            value={input}
                            onKeyDown={event => {
                                if (event.key === 'Enter') {
                                    changePassword();
                                }
                            }}
                        />
                        <Button style={{marginTop: 20}} color="cyan.8" type="submit" onClick={changePassword}>
                            {t('update')}
                        </Button>
                    </div>
            </Modal>
            <ConfirmationModal
                isOpen={isModalOpen}
                title={t('user.deleteUser')}
                text={modalMessage}
                onClose={onModalClose}
                onConfirmation={onModalConfirmation}
            ></ConfirmationModal>
        </>
    );
}
