import useTranslation from '../../../i18next.config';
import {useAgentService} from '../../../services/agent';
import {useEffect, useState} from 'react';
import {Agent} from '../../../types/models/Agent';
import {Button, Paper, Table, createStyles} from '@mantine/core';
import {ERoutes} from '../../../types/enum/routes.enum';
import {useNavigate} from 'react-router-dom';


const useStyles = createStyles({
    longUrl: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '250px',
    }
});

export function AgentsList() {
    const {t} = useTranslation();
    const agentService = useAgentService();
    const navigate = useNavigate();
    const {classes} = useStyles();

    const [agents, setAgents] = useState<Agent[]>([]);

    useEffect(() => {
        agentService
            .getAgents({
                error: error => {
                    console.error(error);
                },
                success: res => {
                    return res.data;
                },
            })
            .then((agentList: Agent[]) => {
                setAgents(agentList);
            });
    }, []);

    const agentsArray = agents ? agents : [];

    const rows = agentsArray.map((agent: Agent) => (
        <tr key={agent._id}>
            <td>{agent.reference}</td>
            <td>{agent.type}</td>
            <td className={classes.longUrl}>
                <span title={agent.url}>
                    {agent.url}
                </span>
            </td>
            <td>{agent.running ? t('agent.running'): t('agent.notRunning')}</td>
            <td>{agent.active ? t('agent.activated') : t('agent.disabled')}</td>
            <td>
                <Button
                    variant='outline'
                    onClick={() => {
                        navigate(ERoutes.ADMIN_UPDATE_AGENT, {
                            state: {agentToUpdate: agent},
                        });
                    }}
                >
                    {t('agent.editAgent')}
                </Button>
            </td>
        </tr>
    ));

    return (
        <>
            <Button
                color="cyan.8"
                type="submit"
                style={{marginBottom: 20}}
                onClick={() => {navigate(ERoutes.ADMIN_ADD_AGENT);}}>
                {t('agent.addAgentBtn')}
            </Button>

            <Paper shadow="md" style={{width: 'fit-content', margin: 'auto'}}>
                <Table>
                    <thead>
                    <tr>
                        <th style={{minWidth: '100px'}}>{t('agent.name')}</th>
                        <th style={{minWidth: '100px'}}>{t('agent.type')}</th>
                        <th style={{minWidth: '140px'}}>{t('agent.url')}</th>
                        <th style={{minWidth: '100px'}}>{t('agent.status')}</th>
                        <th style={{minWidth: '100px'}}>{t('agent.active')}</th>
                        <th>{}</th>
                        <th>{}</th>
                    </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </Table>
            </Paper>
        </>
    );
}
