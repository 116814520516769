import i18next from 'i18next';
import {initReactI18next, useTranslation} from 'react-i18next';

import englishTranslations from './locales/en.json';

i18next.use(initReactI18next).init({
    lng: 'en',
    debug: false,
    resources: {
        en: {
            translation: englishTranslations,
        },
    },
});

export default useTranslation;
