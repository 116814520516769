import {HttpMutators} from './http-mutators';
import {useHttp} from './http';

const useTosService = () => {
    const {get, post, patch} = useHttp('/tos');

    return {
        createTos: (mutators: HttpMutators, payload: any) => post(mutators, {payload}),
        getTosList: (mutators: HttpMutators) => get(mutators),
        getTos: (mutators: HttpMutators, id: string) => get(mutators, {url: `/${id}`}),
        patchTos: (mutators: HttpMutators, id: string, payload: any) =>
            patch(mutators, {url: `/${id}`, payload}),
    };
};

export default useTosService;
