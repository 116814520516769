import {Button, Menu} from '@mantine/core';
import {IconDotsVertical} from '@tabler/icons-react';
import {Execution} from 'src/types/models/Execution';

type ExecutionAction = {
    type: string;
    isDisabled: boolean;
    label: string;
    icon?: JSX.Element;
    onClick?: (event: any, executionRequestId: string) => void;
};
export function ExecutionActionMenu({
    execution,
    executionActions,
}: {
    execution: Execution;
    executionActions: ExecutionAction[];
}) {
    return (
        <Menu position="right" shadow="md" width={200}>
            <Menu.Target>
                <Button>
                    <IconDotsVertical size="1.05rem" stroke={1.5} />
                </Button>
            </Menu.Target>
            <Menu.Dropdown>
                {executionActions.map(action => (
                    <Menu.Item
                        disabled={action.isDisabled}
                        key={action.label}
                        icon={action.icon}
                        onClick={event => (action.onClick ? action.onClick(event, execution._id) : null)}
                    >
                        {action.label}
                    </Menu.Item>
                ))}
            </Menu.Dropdown>
        </Menu>
    );
}
