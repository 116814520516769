import {Badge, Tooltip} from '@mantine/core';
import {ERequestStatus} from 'src/types/enum/request-status.enum';

export function ExecutionStatusBadge(props: {status: ERequestStatus; errorMessage: string}) {
    const getStatusColor = (status: ERequestStatus) => {
        switch (status) {
            case ERequestStatus.DONE:
                return 'green';
            case ERequestStatus.NEW:
                return 'grape';
            case ERequestStatus.RUNNING:
                return 'cyan';
            case ERequestStatus.CANCELED:
                return 'gray';
            case ERequestStatus.ERROR:
                return 'red';
        }
    };

    return (
        <>
            <Tooltip
                multiline
                width={300}
                withArrow
                transition="fade"
                transitionDuration={200}
                label={props.errorMessage}
                disabled={props.status != ERequestStatus.ERROR || !props.errorMessage}
            >
                <Badge color={getStatusColor(props.status)} size="lg">
                    {props.status}
                </Badge>
            </Tooltip>
        </>
    );
}
