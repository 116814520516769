import {Group, Box} from '@mantine/core';
import {Logo} from '../navbar/_logo';

export function Brand() {
    return (
        <Box
            sx={theme => ({
                paddingLeft: theme.spacing.xs,
                paddingRight: theme.spacing.xs,
                paddingBottom: theme.spacing.lg,
            })}
        >
            <Group position="apart">
                <Logo />
            </Group>
        </Box>
    );
}
